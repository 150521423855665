var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"head-bg",class:{
    'head-bgw': _vm.wFlag,
    'head-bgwsmall': _vm.curScroll != 0,
  }},[_c('div',{staticClass:"head-w",class:{ 'head-wdown': _vm.curScroll != 0 }}),_c('div',{staticClass:"head-main"},[_c('a',{staticClass:"logo-box",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$router.push({path:'/welcome'})}}},[_c('h1',[_vm._v("致远速联")])]),_c('div',{staticClass:"menu-box",on:{"mouseover":function($event){return _vm.overItem($event)},"mouseleave":function($event){return _vm.leaveItem($event)}}},[_c('nav',{staticClass:"menu-con"},[_c('a',{staticClass:"menu-item",class:{
          'menu-act':_vm.cur == 'welcome' && _vm.curScroll != 0,
          'menu-act1': _vm.cur == 'welcome' && _vm.curScroll == 0 && !_vm.wFlag,
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goTo($event, 'welcome')}}},[_vm._v("首页")]),_c('a',{staticClass:"menu-item",class:{
          'menu-act':_vm.cur == 'about' && _vm.curScroll != 0,
          'menu-act1': _vm.cur == 'about' && _vm.curScroll == 0 && !_vm.wFlag, 
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goTo($event, 'about')}}},[_vm._v("关于我们")]),_c('a',{staticClass:"menu-item",class:{
          'menu-act':_vm.cur == 'business' && _vm.curScroll != 0,
          'menu-act1': _vm.cur == 'business' && _vm.curScroll == 0 && !_vm.wFlag,
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goTo($event, 'business')}}},[_vm._v(" 业务介绍 ")]),_c('a',{staticClass:"menu-item",class:{
          'menu-act':_vm.cur == 'contact' && _vm.curScroll != 0,
          'menu-act1': _vm.cur == 'contact' && _vm.curScroll == 0 && !_vm.wFlag,
        },attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.goTo($event, 'contact')}}},[_vm._v(" 联系我们 ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }