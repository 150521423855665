import Vue from 'vue'
import VueRouter from 'vue-router'
// import moreRoutes from "./routeAutoImport";
import defRoutes from "@/route.conf";
import P404 from "../views/404"

Vue.use(VueRouter)

const routes = [];
routes.push( ...defRoutes );
// routes.push( ...moreRoutes );
routes.push( {
  path : '*',
  name: '404',
  component : P404
} )

const router = new VueRouter({
  mode: 'history',
 // base: `/${config.version_path}/${config.projectName}/`, //项目部署路径
  routes,
  scrollBehavior( to, from, savedPosition ){
    return {x:0,y:0}
  }
})

/**
 * 定义路由拦截器
 */
router.beforeEach((to, from, next) => {
  if( to.path == '/' )
    next( '/welcome' )
  else
    next();
});

router.afterEach(to => {

});

export default router
