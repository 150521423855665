/**
 * VUE混入及指令
 */
import Vue from "vue";

import {VTooltip} from 'v-tooltip'
VTooltip.options.classes=['el-tooltip__popper' ,'is-dark']
// Vue.use(VTooltip)
VTooltip.options.placement="top"
Vue.directive('tooltip', VTooltip)
