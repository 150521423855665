<template>
  <el-main class="main-box">
      <h1>页面建设中...</h1>
  </el-main>
</template>

<script>
export default {
  name: "P404"
}
</script>

<style lang="less" scoped>
  h1{
    margin-top:10%;
    text-align: center;
  }
</style>