import '../public/main/config'
import Vue from 'vue';
import App from './App.vue';
import './config/autoLoad';
 import router from './config/route';
import $ from 'jquery';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

/*引入BOOTSTRAP*/
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'
/*引入BOOTSTRAP ICONS*/
// import 'bootstrap-icons/font/bootstrap-icons.css'

import './common/prototype.js'



Vue.config.productionTip = false
window.$ = $;
import Api from "./api/index"
Vue.api = Vue.prototype.$Api = Api; //请求接口


window.Vue = new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')


window.$app = {};
 window.$app.router = router;
// window.localUrl = `${location.protocol}//${location.host}/${window.config.version_path}/${window.config.projectName}`
window.localUrl = `${location.protocol}//${location.host}/`

