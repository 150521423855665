/**
 * 自动加载各种默认组件 及配置文件
 */
import Vue from "vue";
import store from "./store";
import cookies from 'js-cookie';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import apiAxios from "./apiAxios";
import globalFunc from './global.func';
import XEUtils from "xe-utils"
import VXETable from "vxe-table"
import "vxe-table/lib/index.css"

Vue.use( Element );
Vue.use( cookies );
Vue.use(VXETable)
Vue.XEUtils=Vue.prototype.$XEUtils=XEUtils;
Vue.prototype.$XModal = VXETable.modal
Vue.prototype.$api = apiAxios;
Vue.prototype.$func = globalFunc;
Vue.prototype.$store = store;
Vue.prototype.$cookies = cookies;
Vue.prototype.$EventBus = new Vue();

Vue.config.errorHandler = function( e, vm ){
    console.error( e )
}

window.$func    = globalFunc;
import './vueExtends';