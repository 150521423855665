const REQ_CODE = {
    'REQ_OK' : '200'
}

import axios from 'axios';
import fileDownload from 'js-file-download';
import qs from 'qs';


let http = axios.create({
    withCredentials: false, //`withCredentials` 表示跨域请求时是否需要使用凭证
    crossDomain:true,
    timeout: 60000
});

http.interceptors.request.use(function (config) {
    if( sessionStorage.getItem( 'USER_TOKEN_TAG' ) != undefined && sessionStorage.getItem( 'USER_TOKEN_VAL' ) !=undefined ){
        let _tokenTag           = sessionStorage.getItem( 'USER_TOKEN_TAG' );
        let _tokenVal           = sessionStorage.getItem( 'USER_TOKEN_VAL' );
        // config.headers.common["set-cookie"] = `${_tokenTag}=${_tokenVal}`;
        config.headers.common[_tokenTag] = _tokenVal;
    }
    return config;
}, function (error) {
    // Do something with request error
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});


/**
 * 全局http请求对象封装
 * @param method String 请求类型： GET|POST|PUT|DELETE
 * @param url String 请求URL路径
 * @param params Object 请求参数
 * @param response function 处理完成的回调函数
 * @param headers Object 发送请求时使用的header信息
 * @param hostName String 主机名称
 * @param responseType String 返回结果类型 arraybuffer|document|json(default)|text|stream
 */
// console.log( window.$app.conf.host );
window.apiAxios = function (method, url, params, response, headers, hostName, responseType) {
    http({
        method: method,
        url: hostName === null || hostName === undefined ? url : hostName + url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        headers: headers !== undefined && headers != null ? headers : null,
        responseType: responseType === undefined ? '' : responseType,
        // crossdomain: true,
    })
    .then(function(res) {
        if (res === null || res === undefined) {
            return;
        }
        /**
         * 通过用判断
         */
        if( false == REQ_CODE.REQ_OK ){
            return ;
        }
        response(res?.data, res);
    })
    .catch(function(err) {
        if (err.response) {
            response(err.response);
        } else if (err.request) {
            console.log( "接口调用失败", err.request );
            /*if (err.request.readyState == 4 && err.request.status == 0 && err.code == "ECONNABORTED") {
                window.apiAxios(method, url, params, response, headers, hostName, responseType)
            }*/
        }
    });
}

/**
 * @fileOverview 监管前端脚手架通用js请求封装
 * @author 郭猛
 * @module $jgApiAxios
 */
export default {
    /**
     * 生成AJAX请求需要的默认头信息
     * @return {{"jg-userId": (string | null), "jg-token": (string | null), "jg-userName": (string), token: string}}
     */
    getDefHeader: function(){
        if( sessionStorage.getItem( 'USER_TOKEN_TAG' ) && sessionStorage.getItem( 'USER_TOKEN_VAL' ) ){
            let _tokenTag           = sessionStorage.getItem( 'USER_TOKEN_TAG' );
            let _tokenVal           = sessionStorage.getItem( 'USER_TOKEN_VAL' );
            let _headers            = [];
            _headers[_tokenTag]     = _tokenVal;
            return _headers;
        }

        if( localStorage.getItem( 'USER_TOKEN_TAG' ) && localStorage.getItem( 'USER_TOKEN_VAL' ) ){
            let _tokenTag           = localStorage.getItem( 'USER_TOKEN_TAG' );
            let _tokenVal           = localStorage.getItem( 'USER_TOKEN_VAL' );
            let _headers            = [];
            _headers[_tokenTag]     = _tokenVal;
            return _headers;
        }
    },

    /**
     * 生成URL
     */
    makeUrl: function( url, cache = false ){
        return cache ? url : `${url}${/\?/g.test(url) ? '&' : '?'}t=${new Date().getTime()}`;
    },
    /**
     * 发送GET请求<br/>该请求会自动添加一个用于防止缓存的时间戳参数 t
     *
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    get: function(url, params, response, hostName, cache = false) {
        let headers = this.getDefHeader();
        url = this.makeUrl( url, cache);
        return window.apiAxios('GET', url, params, response, headers, hostName);
    },

    /**
     * 发送POST请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    post: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        return window.apiAxios('POST', url, params, response, headers, hostName);
    },

    /**
     * 使用REQUEST-BODY方式发送POST请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    postRequestBody: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        headers["Content-Type"] = 'application/json;charset=UTF-8';
        return window.apiAxios('POST', url, params, response, {}, hostName);
    },
    /**
     * 发送PUT请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    put: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        return window.apiAxios('PUT', url, params, response, headers, hostName);
    },

    /**
     * 发送DELETE请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    delete: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        return window.apiAxios('DELETE', url, params, response, headers, hostName);
    },

    /**
     * GET方式发送文件下载请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    download: function(url, params, fileName, response, hostName) {
        let headers = this.getDefHeader();
        return window.apiAxios('GET', url, params, ( r, res ) => {
            if( res.status == 200 ){
                fileDownload( r, fileName );
                response( r );
            }else{
                window.Vue.$message.error( "文件下载失败" );
            }
        }, headers, hostName, "blob");
    },

    /**
     * POST方式发送文件下载请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    downloadPost: function(url, params, responseType, response, hostName) {
        let headers = this.getDefHeader();
        return window.apiAxios('POST', url, params, r => {
            if( response ){
                response( r );
            }
            fileDownload( r.data, fileName );
        }, headers, hostName, "blob");
    },

    /**
     * 使用序列化字符串的方式发送基于键值对的POST请求
     * @param url 请求地址
     * @param params 请求参数
     * @param response 请求结束回调
     * @param hostName 主机名称
     */
    postForm: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
        let qsparam = qs.stringify(params)
        return window.apiAxios('POST', url, qsparam, response, headers, hostName);
    },
    /**
     * 使用from-data方式发送GET请求
     * @param url String 请求地址
     * @param params Object 请求参数
     * @param response Object 请求结束回调
     * @param hostName String 主机名称
     */
    getForm: function(url, params, response, hostName) {
        let headers = this.getDefHeader();
        headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
        return window.apiAxios('GET', url, params, response, headers, hostName);
    },

    /**
     * 使用序列化字符串的方式发送基于FROM-DATA的POST请求
     * @param url
     * @param params
     * @param response
     * @param hostName
     */
    postRequestForm: function(url, params, response, hostName) {
        let FormData    = require('form-data');
        let data        = new FormData();
        Object.keys( params ).forEach( _k => {
          data.append( _k, params[_k] );
        } )
        let headers = this.getDefHeader();
        return window.apiAxios('POST', url, data, response, headers, hostName);
    },

    delete : function( url, params, response, hostName ){
        let headers = this.getDefHeader();
        return window.apiAxios('DELETE', url, params, response, headers, hostName);
    }
};