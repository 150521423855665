<style scoped>
    .foot-bg{width: 100%;background-color: #fafafa;}
    .foot-bgw {
      background-color: #fff;
    }
    .foot-main{padding: 30px 0px;}
    .foot-item{display: flex;align-items: center;justify-content: space-between;height: 60px;font-size: 16px;color: #666;padding: 0 20px}
    .foot-itemus{display: flex;align-items: center;justify-content: space-between;height: 60px;font-size: 18px;color: #666;padding: 0 30px;position: relative;}
    .foot-itemact{background-color: #ebebeb;}
    .foot-lab{}
    .foot-labweixin{/*background: url(../../static/img/common/i-weixin.svg) no-repeat right center;padding-right: 36px;*/}
    .foot-labweixinact{/*background: url(../../static/img/common/i-weixinact.svg) no-repeat right center;*/}
    .foot-icon{width: 30px;height: 30px;transition: all 0.3s;}
    .icon-tran{transform: rotate(180deg);}
    .foot-conbox{transition: all 0.3s;overflow: hidden;}
    .foot-con{font-size: 18px;color: #888;height: 40px;display: flex;align-items: center;}
    .foot-copy{color: #999;font-size: 12px;margin-top: 20px;padding: 0 30px;}
    .foot-linklogis{color: #999;font-size: 13px;margin-top: 6px;padding: 0 30px;}
    .foot-icp{color: #999;font-size: 13px;margin-top: 6px;padding: 0 30px;}
    .foot-wxout{width: 150px;height: 170px;/*background:url(../../static/img/common/out.svg) no-repeat center center;*/position:absolute;left: 48px;top: -155px;transition: all 0.3s;display: flex;justify-content: center;align-items: center;background-size: 100%;}
    .foot-wxcode{/*background: url(../../static/img/common/linklogis.png) no-repeat center center;*/width: 145px;height: 140px;background-size: 100%;}

    .logo-box{display: flex;align-items: center;justify-content: center;padding-top: 20px;padding-bottom: 20px;}
    .logo-item{display: flex;flex-direction: column;align-items: center;font-size: 12px;color: #666;margin:0px 12px;}
    .logo-item div:nth-child(1){width: 100px;height: 100px;border-radius: 8px;border: 1px solid #E2E2E2;margin-bottom: 8px;}
    .logo-weixin{background: url(../../static/img/common/public.png)no-repeat center center;background-size: 100%;}
    .logo-linkedin{background: url(../../static/img/common/eaeapublic.png)no-repeat center center;background-size: 100%;}

    .foot-btn{height: 60px;width: 100%;background-color:#F1F1F1;display: flex;justify-content: center;align-items: center;font-size: 18px;color: #666;}

    .foot-item1{color:#ddd;}
    .link-lab title{font-size: 1.9rem;}
    .lls-line-right{background:url(../../static/img/common/right-arrow.svg) no-repeat 50%;}
</style>

<template>
    <div class="foot-bg " @click="showWXcode=false" :class="{'foot-bgw':theme}">
        <!-- 主体 -->
        <div class="foot-main">
              <router-link to="/about/" class="foot-item"  :class="{'foot-itemact':showFlag=='about'}">
                <div class="foot-lab">关于我们</div>
                <div class="foot-icon lls-line-right"></div>
            </router-link>
            <router-link to="/business/" class="foot-item" :class="{'foot-itemact':showFlag=='business'}">
                <div class="foot-lab">业务介绍</div>
                <div class="foot-icon lls-line-right"></div>
            </router-link>
             <router-link to="/contact/" class="foot-item" :class="{'foot-itemact':showFlag=='contact'}">
                <div class="foot-lab">联系我们</div>
                <div class="foot-icon lls-line-right"></div>
            </router-link>
            <!-- <div class="foot-item">
                <p class="link-lab">公司电话:</p>
                <p style="font-size:14px">+86 0755-8239 8100</p>
              
            </div>
              <div class="foot-item">
                <div class="link-lab">公司地址:</div>
                <p style="font-size:14px">深圳市罗湖区红岭中路2068号中深国际大厦24楼</p>
               
            </div> -->
            <div class="logo-box">
                <div class="logo-item">
                    <div class="logo-weixin"></div>
                    <div>关注致远速联</div>
                </div>
                <div class="logo-item">
                    <div class="logo-linkedin"></div>
                    <div>关注易问易答</div>
                </div>
            </div>
              
                <div class="foot-linklogis">深圳市致远速联信息技术有限公司 版权所有</div>
                <div class="foot-icp"><a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备13055653号-2 </a></div>
          
        </div>
        <!-- 回到顶部 -->
        <div class="foot-btn" @click="goTop()">返回顶部</div>
    </div>
</template>

<script>
    export default {
        components: {},
        data (){
            return {
                showFlag: "",//显示的下标
                showWXcode: false,
                //国际化
              //  foot: this.offLang.foot,
              //  common: this.offLang.common,
                theme: '',
            }
        },
        mounted:function(){ 
          console.log(this.$r);
          // 临时处理
          if (this.$route.fullPath=='/' || this.$route.fullPath=='/welcome/') {
            this.theme = true
          }
        },
        watch: {
            showFlag: function(newVal,oldVal) {
                if(newVal) {
                    var _this = this;
                    setTimeout(function(){
                        _this.showFlag = "";
                    },500)
                }
            }
        },
        methods:{
            //跳转菜单
            goTo: function(path) {
                var _this = this;
                this.showFlag = path;
                this.$router.push({
                    path: '/' + path,
                    query: {}
                })
                this.showMenu = false;
                this.showWXcode = false;
                var url = document.URL;
                if(url.indexOf(path) != -1) {
                    this.goTop();
                }
            },
            //回到顶部
            goTop: function() {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
                this.showWXcode = false;
            },
            //关注我们
            showUs: function(e) {
                e.stopPropagation();
                this.showWXcode = !this.showWXcode;
            }
        }
    }
</script>


