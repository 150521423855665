<template>
  <div>
    <!-- 背景视频 -->
    <div class="all-bg" :class="{ 'all-bg-m': mediaTer == 'Mobile' }">
      <div class="bg-mark"></div>
      <video width="100%" id="video1" autoplay="" loop="" muted="" x-webkit-airplay="true" airplay="allow"
       webkit-playsinline="true" playsinline="true" :src="video1" class="bg-video"></video>
      <h2 class="sp-title">
        <p>非宁静无以致远，唯谦和得以速联</p>
        <p>——合作 共赢 信任——</p>
        <p>Genesys Information Technology Co.,Ltd</p>
      </h2>
      <div class="sp-play" @click="toPlayVideo"></div>
      <div class="sp-ami">
        <div class="ami-line"></div>
      </div>
    </div>
    <!-- 科技优势 -->
    <div class="ys-bg" >
      <div class="ys-main" :class="{ 'ys-main-m': mediaTer == 'Mobile' }">
        <div
          class="ys-top"
          ref="wel1"
          :class="flag['wel1'] ? 'moveToTopM' : 'old-placeM'"
        >
          <p
            >利用AI赋能资本市场数字化，涵盖规则、系统、数据三大方向</p
          >
          <p>为核心对象提供专属化方案，呈现实时感知、可视可控、精益高效的业务形态。</p>
        </div>
        <el-row>
          <el-col :lg="8" :md="24">
            <div class="ys-title">致远速联</div>
            <div class="ys-title">数字化研究中心</div>
          </el-col>
          <el-col :lg="16" :md="24">
            <div class="ys-title">致远速联</div>
            <div class="ys-title">数字信息与产品开发中心</div>
          </el-col>
        </el-row>
        <div
          class="ys-imgbox"
          ref="wel2"
          :class="flag['wel2'] ? 'moveToTop' : 'old-place'"
        >
        <el-row>
          <el-col :lg="8" :md="24">
            <div>
          <div class="ys-item ys-szh" @click="goTo('business', 'cmdr')">
            <div class="ys-text">数字化系统</div>
            <!-- <div class="ys-icon"></div> -->
            <div class="ys-msg">
            <div> 深耕数据 云端加密</div>
            <div>探索多维财富管理的创新载体</div> 
            </div>
          </div>
          <div class="ys-desc"></div>
        </div>
           </el-col>
           <el-col :lg="8" :md="24">
            <div>
          <div class="ys-item ys-api" @click="goTo('business', 'api')">
            <div class="ys-text">API开放平台</div>
            <!-- <div class="ys-icon"></div> -->
            <div class="ys-msg">
             <div> 安全合规 渗透场景</div>
             <div> 探索资本市场数字化的共享生态</div>
            </div>

           
          </div>
          <!-- <div class="ys-desc">
               <div>
               <div> 主要客户</div>
               <ul>
                  <li>咨询服务机构、投研服务机构、金融从业人员等</li>
               </ul>
              </div>
              <div>
               <div> 产品/客户</div>
               <ul>
                  <li>CMDP数字化工作台</li>
                  <li>CMDP随身数字智库</li>
               </ul>
              </div>
              <div>
               <div> 盈利模式</div>
               <ul>
                  <li>咨询服务机构、投研服务机构、金融从业人员等</li>
               </ul>
              </div>

            </div> -->
        </div>
           </el-col>
           
           <el-col :lg="8" :md="24">
            <div>
          <div class="ys-item ys-bps" @click="goTo('business', 'bps')">
            <div class="ys-text">BPS智能业务</div>
            <!-- <div class="ys-icon"></div> -->
            <div class="ys-msg">
            <div> 赋能客户 面向未来</div>
            <div>探索低耗能业务模式的云端体验</div> 
            </div>
          </div>
          <div class="ys-desc"></div>
        </div>
           </el-col>
        </el-row>
        
        
        
        </div>
      </div>
    </div>
    <!-- 人工智能&区块链 改为我们的优势 -->
    <div class="ai-bg" :class="{ 'ai-bg-m': mediaTer == 'Mobile' }">
      <div class="ai-main" :class="{ 'ai-main-m': mediaTer == 'Mobile' }">
        <div v-if="mediaTer == 'Mobile'" class="ai-top" ref="wel3" :class="flag['wel3'] ? 'moveToTop' : 'old-place'" >我们的优势</div>
        <div v-else class="ai-top" ref="wel3" :class="flag['wel3'] ? 'moveToTop' : 'old-place'" >我们的优势</div>
        <div class="ai-con">
          <div class="ai-item">
             <div class="ai-text1">数据多维处理</div>
              <div class="ai-msg1">
                <p>第一手数据源的获取</p> 
                <p>官方数据源的整合</p>
                <p>全流程留痕加工处理</p>
              </div>
          </div>
          <div class="ai-item">
            <div class="ai-text1">业务持续深耕</div>
              <div class="ai-msg1">
                <p>行业信息化标准制定者</p> 
                <p>24h业务规则化处理能力</p>
                <p>20年深交所监管服务团队</p>
              </div>
          </div>
          <div class="ai-item">
            <div class="ai-text1">技术深度挖掘</div>
              <div class="ai-msg1">
                <p>深证通强大的云支持</p> 
                <p>一线业务建模能力积累</p>
                <p>20年监管信息化建设经验</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 科技成果 -->
    <div class="cg-bg" :class="{ 'cg-bg-m': mediaTer == 'Mobile' }">
      <div class="cg-main" :class="{ 'cg-main-m': mediaTer == 'Mobile' }">
        <div
          class="cg-detail"
          ref="wel4"
          :class="flag['wel4'] ? 'moveToTop' : 'old-place'"
        >
          <div class="cg-item">
            <div class="cg-outcon">
              <countTo
                :startVal="0"
                :endVal="3000"
                :duration="1000"
                key="1"
                v-if="flag['wel4']"
              ></countTo>
              <span class="cg-unit cg-unit1">+</span>
            </div>
            <div>服务上市公司</div>
          </div>
          <div class="cg-item">
            <div class="cg-outcon">
              <countTo
                :startVal="0"
                :endVal="20"
                :duration="1000"
                key="1"
                v-if="flag['wel4']"
              ></countTo>
              <span class="cg-unit cg-unit1">年</span>
            </div>
            <div>与核心机构合作</div>
          </div>
          <div class="cg-item">
            <div class="cg-outcon">
              <countTo
                :startVal="0"
                :endVal="100000"
                :duration="1000"
                key="1"
                v-if="flag['wel4']"
              ></countTo>
              <span class="cg-unit cg-unit1">+</span>
            </div>
            <div>处理定期报告披露信息</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作客户 -->
    <div class="kh-bg" :class="{ 'kh-bg-m': mediaTer == 'Mobile' }">
      <div class="kh-w">
        <div
          class="kh-title"
          ref="wel5"
          :class="flag['wel4'] ? 'moveToTop' : 'old-place'"
        >
          服务客户
        </div>
     
      <div
        class="kh-box"
        ref="wel6"
        :class="flag['wel6'] ? 'moveToTop' : 'old-place'"
      >
        <div
          class="kh-comp"
         
          :class="{ 'kh-comp-m': mediaTer == 'Mobile' }"
        >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div></div>
         </div>
    </div>
    <!-- 全屏播放 -->
    <div
      class="bf-bg"
      :style="{
        width: allPlay ? '100%' : '0%',
        height: allPlay ? '100%' : '0%',
        top: allPlay ? '0%' : '50%',
        left: allPlay ? '0%' : '50%',
        opacity: allPlay ? 1 : 0,
      }"
    >
    <template v-if="mediaTer == 'Mobile'">
       <video width="100%" id="video2m" autoplay="" loop="" muted="" x-webkit-airplay="true" controls=""
      airplay="allow" webkit-playsinline="true" playsinline="true" :src="video2" class="bg-video-m"></video>
    </template>
    <template v-else>
    <video width="100%" id="video2" autoplay="" loop="" muted="" x-webkit-airplay="true" controls=""
     airplay="allow" webkit-playsinline="true" playsinline="true" :src="video2" class="bg-video"></video>
    
    </template>
    <div
        class="bf-close bf-close-m"
        @click="closeVideo"
        :class="{ 'bf-close-m': mediaTer == 'Mobile' }"
      ></div>
    </div>
  
  </div>
</template>

<script>
import Vue from "vue";
import countTo from "vue-count-to";
import video1 from "@/static/videos/video1.mp4";
import video2 from "@/static/videos/video2.mp4";
export default {
  name: "welcome",
  components: { countTo },
  data() {
    return {
      tabIndex: 0,
      flag: {},
      viewHeight: 0, //页面可视区域高度
      origin: "", //页面域名
      mediaTer: "", //终端类型
      allPlay: false, //是否全屏播放视频
      video1: video1,
      video2: video2
    };
  },
  methods: {
    //页面初始化
    pageInit: function(name, num) {
      var _this = this;
      _this.viewHeight = this.getViewHeight();
      //初始化dom信息
      for (let i = 1; i < num; i++) {
        _this.flag["dom" + i] = _this.$refs[name + "" + i];
        if (_this.flag["dom" + i]) {
          let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
          if (rectY < _this.viewHeight) {
            Vue.set(_this.flag, name + "" + i, true);
          } else {
            Vue.set(_this.flag, name + "" + i, false);
          }
          _this.$forceUpdate();
        }
      }
    },
    //页面滚动动画
    pageScrollAmi: function(name, num) {
      var _this = this;
      window.onscroll = function() {
        for (let i = 1; i < num; i++) {
          if (_this.flag["dom" + i]) {
            let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
            if (rectY < _this.viewHeight) {
              Vue.set(_this.flag, name + "" + i, true);
            } else {
              Vue.set(_this.flag, name + "" + i, false);
            }
            _this.$forceUpdate();
          }
        }

        //页面滚动高度
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > _this.$parent.scrollNum) {
          //向下滚动
          _this.$parent.hideFlag = true;
        } else {
          //向上滚动
          _this.$parent.hideFlag = false;
        }
        _this.$parent.scrollNum = scrollTop;
      };
    },
    //页面尺寸变化
    pageSize: function() {
      var _this = this;
      window.onresize = function() {
        _this.viewHeight = _this.getViewHeight();
      };
    },
    //跳转到业务系统介绍
    goTo: function(path, type) {
      this.$router.push({
        path: "/business",
        query: { type: type }
      });
    },
    //播放视频
    toPlayVideo: function() {
      // let {lang} = this.offLang
      // this.$refs.videoBG.pause()
      // this.$refs.bgVideo.play()
      // this.llsSrc = lang == "EN" ? "../../static/bg-en.m4v" : "../../static/bg-cn.m4v";
      this.allPlay = true;
    },
    closeVideo() {
      // this.$refs.bgVideo.pause()
      // this.$refs.videoBG.play()
      this.allPlay = false;
    }
  },
  mounted() {
    var num = this.mediaTer == "Mobile" ? 7 : 7;
    //页面初始化
    this.pageInit("wel", num);
    //页面滚动动画
    this.pageScrollAmi("wel", num);
    //页面尺寸变化
    this.pageSize();
  },
  created: function() {
    //获取页面域名
    this.origin = document.location.origin;
    //获取访问环境
    this.mediaTer = this.getEnvironment();
  }
};
</script>

<style scoped>
/* 公共 */
.w-title {
  font-size: 24px;
  color: #fff;
  margin: 30px 0px;
  text-align: center;
}
/* 视频背景 */
.all-bg {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column; /*background: url(../../static/img/welcome/bg.jpg) no-repeat center center;background-size: 100% 100%;*/
  background-color: #000;
}
.bg-mark {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.bg-video {
  object-fit: fill;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.sp-title {
  /* text-indent: -888888px; */
  width: 700px;
  height: 170px;
  /* background: url(../../static/img/welcome/v-title.svg) no-repeat center center; */
  z-index: 2;
  background-size: 90%;
  color: #fff;
  text-align: center;
  font-size: 32px;
}
.sp-title-en {
  /* background: url(../../static/img/welcome/v-title-en.svg) no-repeat center
    center; */
  background-size: 90%;
}
.all-bg-m .sp-title {
  font-size: 1.5em;
}
.sp-play {
  width: 45px;
  height: 45px;
  opacity: 0.3;
  background: url(../../static/img/welcome/play.svg) no-repeat center center;
  z-index: 2;
  background-size: 100%;
  cursor: pointer;
}
.sp-play:hover {
  opacity: 1;
}
.sp-ami {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}
.ami-line {
  width: 2px;
  height: 36px;
  background-color: #fff;
  margin-bottom: 10px;
  animation: toNone 2.5s 0.5s ease infinite;
  position: absolute;
  bottom: 0px;
}
@keyframes toNone {
  0% {
    height: 36px;
    bottom: 0px;
  }
  35% {
    height: 0px;
    bottom: 0px;
  }
  65% {
    height: 0px;
    bottom: 36px;
  }
  100% {
    height: 36px;
    bottom: 0px;
  }
}
.all-bg iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-width: 0px;
}
.all-bg-m {
  height: 500px;
}
.all-bg-m .sp-title {
  width: 100%;
}

/* 科技优势 */
/* .ys-bg { width: 100%; background-color: #151e2c; } */
.ys-bg {
  width: 100%;
}
.ys-main {
  width: 1200px;
  margin: auto;
  padding: 40px 0px 50px;
}
/* .ys-top { font-size: 18px; color: #fff; text-align: center; padding: 40px 0 40px; line-height: 35px; transition: all 0.5s; } */
.ys-top {
  font-size: 24px;
  color: #151e2c;
  text-align: center;
  padding: 80px 0 80px;
  line-height: 44px;
  transition: all 0.5s;
}

.ys-title{
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: rgb(59,76,125);
}

.ys-imgbox {
  /* display: flex; */
  width: 100%;
  flex-wrap: wrap;
  transition: all 0.5s;
}
/* .ys-imgbox .ys-item { display: flex; align-items: center; justify-content: center; width: 50%; height: 250px; cursor: pointer; position: relative; overflow: hidden; transition: all 0.3s; color: #fff; } */
.ys-item {
  position: relative;
  /* width: 50%; */
  /* width: 33%; */
  height: 250px;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
}
/* .ys-imgbox .ys-item:nth-child(1) {
  border-radius: 4px 0 0 0;
}
.ys-imgbox .ys-item:nth-child(2) {
  border-radius: 0 4px 0 0;
}
.ys-imgbox .ys-item:nth-child(3) {
  border-radius: 0 0 0 4px;
}
.ys-imgbox .ys-item:nth-child(4) {
  border-radius: 0 0 4px 0;
} */
.ys-item .ys-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  transition: all 0.3s;
  white-space: nowrap;
}
/* .ys-item .ys-icon { width: 44px; height: 30px; background: url(../../static/img/common/i-right-w.svg) no-repeat center center; position: absolute; bottom: -90px; opacity: 0; transition: all 0.3s; background-size: 80%; left: calc(50% - 22px); } */
/* .ys-item .ys-msg { font-size: 18px; position: absolute; bottom: -60px; transition: all 0.3s; width: calc(100% - 100px); left: 50%; opacity: 0; text-align: center; } */
.ys-item .ys-msg {
  position: absolute;
  left: 50%;
  top: 300px;
  width: calc(100% - 80px);
  transform: translateX(-50%);
  text-align: center;
  font-size: 18px;
  opacity: 0;
  transition: all 0.3s;
}
.ys-item .ys-msg::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 50%;
  width: 44px;
  height: 30px;
  transform: translateX(-50%);
  background: url(../../static/img/common/i-right-w.svg) no-repeat center center;
  background-size: 80%;
}
/* 改 */
/* .ys-imgbox > div:nth-child(1) { background: url(../../static/img/welcome/core.png) no-repeat center center; }
.ys-imgbox > div:nth-child(2) { background: url(../../static/img/welcome/busi.png) no-repeat center center; }
.ys-imgbox > div:nth-child(3) { background: url(../../static/img/welcome/cbc.png) no-repeat center center; }
.ys-imgbox > div:nth-child(4) { background: url(../../static/img/welcome/k-fintech.png) no-repeat center center; } */
.ys-api {
  background: url(../../static/img/welcome/api.png) no-repeat center center;
  border-radius: 4px 0 0 0;
}
.ys-szh {
  background: url(../../static/img/welcome/szh.png) no-repeat center center;
  border-radius: 0 4px 0 0;
}
.ys-bps {
  background: url(../../static/img/welcome/bps.png) no-repeat center center;
  border-radius: 0 0 0 4px;
}
.ys-imgbox > div:nth-child(4) {
  /* background: url(../../static/img/welcome/cloud4.png) no-repeat center center; */
}
.ys-item:hover .ys-text {
  top: 70px;
}
.ys-item:hover .ys-msg {
  opacity: 1;
  /* top: 120px; */
  top: 140px;
}
.ys-main-m {
  width: 100%;
  padding: 40px 0 10px;
}
.ys-main-m .ys-top {
  line-height: 24px;
  padding: 0 20px 30px;
  font-size: 16px;
}
.ys-main-m .ys-imgbox {
  width: auto;
  padding: 0px 20px;
}

.ys-main-m .ys-item {
  width: 100%;
  margin-bottom: 10px;
  height: 220px;
  border-radius: 4px !important;
}
.ys-main-m .ys-text {
  font-size: 24px;
}
.ys-main-m .ys-item:hover .ys-text {
  top: 50px;
}
.ys-main-m .ys-item:hover .ys-msg {
  top: 85px;
}
.ys-main-m .ys-item-en:hover .ys-text {
  top: 40px;
}
.ys-main-m .ys-item-en:hover .ys-msg {
  top: 60px;
  width: calc(100% - 20px);
}
.ys-main-m .ys-imgbox .ys-item-en:nth-child(1) .ys-msg {
  width: calc(100% - 20px);
}
.ys-main-m .ys-imgbox .ys-item-en:nth-child(1) .ys-msg::after {
  bottom: -25px;
}
/* .ys-item:hover .ys-text { padding-bottom: 110px; }
.ys-item:hover .ys-msg { opacity: 1; bottom: 90px; }
.ys-item:hover .ys-icon { opacity: 1; bottom: 50px; }
.ys-item-en:hover .ys-text { padding-bottom: 140px; }
.ys-item-en:hover .ys-msg { opacity: 1; bottom: 60px; }
.ys-item-en:hover .ys-icon { opacity: 1; bottom: 20px; }
.ys-main-m { width: 100%; padding: 40px 0 10px; }
.ys-main-m .ys-top { line-height: 26px; padding: 0 20px 30px; font-size: 16px; }
.ys-main-m .ys-imgbox { width: auto; padding: 0px 20px; }
.ys-main-m .ys-item { width: 100%; margin-bottom: 10px; height: 220px; border-radius: 4px !important; }
.ys-main-m .ys-item:hover .ys-msg { bottom: 70px; text-align: center; }
.ys-main-m .ys-text { font-size: 24px; } */

/* 改 end */

/* 人工智能&区块链 */
.ai-bg {
  /* height: 540px; */
  width: 100%;
  background: url(../../static/img/welcome/ai-bg.png) no-repeat center center;
  background-size: 100% 100%;
  /* background-size: auto 100%; */
  color: #fff;
  padding: 80px 0;
}
.ai-main {
  width: 1200px;
  margin: 0 auto;
}
.ai-top {
  padding-bottom: 50px;
  text-align: center;
  font-size: 24px;
  line-height: 44px;
  transition: all 0.5s ease;
}

.ai-main-m {
  width: 100%;

  background-size: 100% auto;
}
.ai-con {
  display: flex;
  /* border-radius: 8px; */
  overflow: hidden;
}
.ai-main-m .ai-con {
  width: auto;
  padding: 0px 20px;
}

.ai-main-m .ai-item {
  width: 100% !important;
  margin-bottom: 10px !important;
  height: 220px;
  border-radius: 4px !important;
}

.ai-con .ai-item {
  position: relative;
  /* width: 50%; */
  width: 33%;
  height: 250px;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid;
}

.ai-con-l,
.ai-con-r {
  position: relative;
  width: 600px;
  max-width: 600px;
  margin: 0 auto;
  height: 248px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
}
.ai-con-l:hover,
.ai-con-r:hover {
  background-color: rgba(0, 0, 0, 0.16);
}
.ai-con-l .ai-logo {
  height: 56px;
  width: 230px;
  /* background: url(../../static/img/welcome/ai-logo1.svg) no-repeat center center; */
  position: absolute;
  top: 61px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 20px;
  transition: all 0.3s ease;
}
.ai-con-r .ai-logo {
  height: 50px;
  width: 235px;
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  /* background: url(../../static/img/welcome/ai-logo2.svg) no-repeat center center; */
  transition: all 0.3s ease;
}
.ai-text {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.ai-text1 {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.ai-msg1 {
  position: absolute;
  top: 108px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 18px;
  transition: all 0.3s ease;
}

.ai-msg {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 18px;
  transition: all 0.3s ease;
}
.ai-msg::after {
  content: "";
  position: absolute;
  /* top: 300px; */
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 76px;
  height: 28px;
  /* background: url(../../static/img/common/i-right-w.svg) no-repeat center center; */
  background-size: 50% 80%;
  /* border: 1px solid #fff; */
  border-radius: 2px;
  transition: all 0.3s ease;
}
.ai-con-l:hover .ai-logo {
  top: 16px;
}
.ai-con-r:hover .ai-logo {
  top: 20px;
}
.ai-con-l:hover .ai-text,
.ai-con-r:hover .ai-text {
  top: 91px;
}
.ai-con-l:hover .ai-msg,
.ai-con-r:hover .ai-msg {
  top: 135px;
}
.ai-bg-m {
  padding: 0;
  box-sizing: border-box;
}
.ai-main-m {
  width: 100%;
  padding: 40px 0px 10px;
}
.ai-main-m .ai-top {
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 30px;
  /* width: 346px; */
  /* margin-left: -5px; */
}
.ai-main-m .ai-con {
  display: block;
}
.ai-main-m .ai-con-l,
.ai-main-m .ai-con-r {
  width: 100%;
}
.ai-main-m .ai-logo {
  transform: translateX(-50%);
  /* .ai-main-m .ai-con-r{ */
}
.ai-main-m .ai-text {
  font-size: 24px;
}

/* 合作客户 */
.kh-bg {
  width: 100%;
  background-color: #fafafa;
}
.kh-w {
  width: 1200px;
  margin: auto;
}
.tabact {
  color: #333;
}
.kh-title {
  font-size: 32px;
  color: #333;
  padding: 80px 0 20px;
  text-align: center;
  transition: all 0.5s;
}
.kh-tab {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #e2e2e2;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.kh-comp {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 10px;
}
.kh-comp div:nth-child(1) {
  background: #fff url(../../static/img/welcome/joinwin1.png) no-repeat center
    center;
  /* background-size: 25% !important; */
}
.kh-comp div:nth-child(2) {
  background: #fff url(../../static/img/welcome/joinwin2.png) no-repeat center
    center;
  /* background-size: 60% !important; */
}
.kh-comp div:nth-child(3) {
  background: #fff url(../../static/img/welcome/joinwin3.png) no-repeat center
    center;
}
.kh-comp div:nth-child(4) {
  background: #fff url(../../static/img/welcome/joinwin4.png) no-repeat center
    center;
  /* background-size: 50% !important; */
}
.kh-comp div:nth-child(5) {
  background: #fff url(../../static/img/welcome/joinwin5.png) no-repeat center
    center;
  /* background-size: 50% !important; */
}
/* .kh-comp div:nth-child(6) { background: url(../../static/img/welcome/comp6.png) no-repeat center center; background-size: 50% !important; } */
.kh-comp div:nth-child(6) {
  background: #fff url(../../static/img/welcome/joinwin6.png) no-repeat center
    center;
}
.kh-comp div:nth-child(7) {
  background: #fff url(../../static/img/welcome/joinwin7.png) no-repeat center
    center;
}
.kh-comp div:nth-child(8) {
  background: #fff url(../../static/img/welcome/joinwin8.png) no-repeat center
    center;
  /* background-size: 50% !important; */
}
.kh-comp div:nth-child(9) {
  background: #fff url(../../static/img/welcome/joinwin9.png) no-repeat center
    center;
}
.kh-comp div:nth-child(10) {
  background: #fff url(../../static/img/welcome/joinwin10.png) no-repeat center
    center;
  /* background-size: 20% !important; */
}
.kh-comp div:nth-child(11) {
  background: #fff url(../../static/img/welcome/joinwin11.png) no-repeat center
    center;
  /* background-size: 40% !important; */
}
.kh-comp div:nth-child(12) {
  background: #fff url(../../static/img/welcome/joinwin12.png) no-repeat center
    center;
}
.kh-comp div:nth-child(13) {
  background: #fff url(../../static/img/welcome/joinwin13.png) no-repeat center
    center;
  /* background-size: 20% !important; */
}
.kh-comp div:nth-child(14) {
  background: #fff url(../../static/img/welcome/joinwin14.png) no-repeat center
    center;
}
.kh-comp div:nth-child(15) {
  background: #fff url(../../static/img/welcome/joinwin15.png) no-repeat center
    center;
  /* background-size: 27% !important; */
}
.kh-comp div:nth-child(16) {
  /* background: #fff url(../../static/img/welcome/comp17.png) no-repeat center
    center; */
  background-size: 50% !important;
}
.kh-comp div:nth-child(17) {
  /* background: #fff url(../../static/img/welcome/comp18.png) no-repeat center
    center; */
}
.kh-comp div:nth-child(18) {
  /* background: #fff url(../../static/img/welcome/comp19.png) no-repeat center
    center; */
  background-size: 25% !important;
}
.kh-comp div:nth-child(19) {
  /* background: #fff url(../../static/img/welcome/comp20.png) no-repeat center
    center; */
  background-size: 25% !important;
}
.kh-comp div:nth-child(20) {
  /* background: #fff url(../../static/img/welcome/comp21.png) no-repeat center
    center; */
  background-size: 55% !important;
}
/* .kh-comp div:nth-child(21) {
  background: #fff url(../../static/img/welcome/comp22.png) no-repeat center
    center;
  background-size: 55% !important;
}
.kh-comp div:nth-child(22) {
  background: #fff url(../../static/img/welcome/comp23.png) no-repeat center
    center;
  background-size: 50% !important;
}
.kh-comp div:nth-child(23) {
  background: #fff url(../../static/img/welcome/comp24.png) no-repeat center
    center;
  background-size: 40% !important;
}
.kh-comp div:nth-child(24) {
  background: #fff url(../../static/img/welcome/comp25.png) no-repeat center
    center;
  background-size: 33% !important;
}
.kh-comp div:nth-child(25) {
  background: #fff url(../../static/img/welcome/comp26.png) no-repeat center
    center;
  background-size: 50% !important;
}
.kh-comp div:nth-child(26) {
  background: #fff url(../../static/img/welcome/comp27.png) no-repeat center
    center;
  background-size: 30% !important;
}
.kh-comp div:nth-child(27) {
  background: #fff url(../../static/img/welcome/comp28.png) no-repeat center
    center;
  background-size: 35% !important;
}
.kh-comp div:nth-child(28) {
  background: #fff url(../../static/img/welcome/comp29.png) no-repeat center
    center;
  background-size: 30% !important;
}
.kh-comp div:nth-child(29) {
  background: #fff url(../../static/img/welcome/comp30.png) no-repeat center
    center;
  background-size: 30% !important;
}
.kh-comp div:nth-child(30) {
  background: #fff url(../../static/img/welcome/comp31.png) no-repeat center
    center;
  background-size: 30% !important;
} */
.kh-comp div {
  box-sizing: border-box;
  width: calc((100% - 50px) / 5);
  height: 80px;
  margin: 5px;
  padding: 20px 0;
  background-size: 80% !important;
  border-radius: 4px;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.04);
  box-sizing: content-box;
}
.kh-comp-m div {
  height: 40px;
}

.kh-bank {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 10px;
}
/* .kh-bank div:nth-child(1) {
  background: #fff url(../../static/img/welcome/bank1.png) no-repeat center
    center;
  background-size: 55% !important;
}
.kh-bank div:nth-child(2) {
  background: #fff url(../../static/img/welcome/bank2.svg) no-repeat center
    center;
  background-size: 35% !important;
}
.kh-bank div:nth-child(3) {
  background: #fff url(../../static/img/welcome/bank3.png) no-repeat center
    center;
  background-size: 70% !important;
}
.kh-bank div:nth-child(4) {
  background: #fff url(../../static/img/welcome/bank4.png) no-repeat center
    center;
}
.kh-bank div:nth-child(5) {
  background: #fff url(../../static/img/welcome/bank5.png) no-repeat center
    center;
}
.kh-bank div:nth-child(6) {
  background: #fff url(../../static/img/welcome/bank6.png) no-repeat center
    center;
}
.kh-bank div:nth-child(7) {
  background: #fff url(../../static/img/welcome/bank7.png) no-repeat center
    center;
}
.kh-bank div:nth-child(8) {
  background: #fff url(../../static/img/welcome/bank8.png) no-repeat center
    center;
}
.kh-bank div:nth-child(9) {
  background: #fff url(../../static/img/welcome/bank9.png) no-repeat center
    center;
}
.kh-bank div:nth-child(10) {
  background: #fff url(../../static/img/welcome/bank10.png) no-repeat center
    center;
}
.kh-bank div:nth-child(11) {
  background: #fff url(../../static/img/welcome/bank11.png) no-repeat center
    center;
}
.kh-bank div:nth-child(12) {
  background: #fff url(../../static/img/welcome/bank12.png) no-repeat center
    center;
}
.kh-bank div:nth-child(13) {
  background: #fff url(../../static/img/welcome/bank13.png) no-repeat center
    center;
}
.kh-bank div:nth-child(14) {
  background: #fff url(../../static/img/welcome/bank14.png) no-repeat center
    center;
}
.kh-bank div:nth-child(15) {
  background: #fff url(../../static/img/welcome/bank15.png) no-repeat center
    center;
}
.kh-bank div:nth-child(16) {
  background: #fff url(../../static/img/welcome/bank16.png) no-repeat center
    center;
}
.kh-bank div:nth-child(17) {
  background: #fff url(../../static/img/welcome/bank17.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(18) {
  background: #fff url(../../static/img/welcome/bank18.png) no-repeat center
    center;
  background-size: 40% !important;
}
.kh-bank div:nth-child(19) {
  background: #fff url(../../static/img/welcome/bank19.png) no-repeat center
    center;
}
.kh-bank div:nth-child(20) {
  background: #fff url(../../static/img/welcome/bank20.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(21) {
  background: #fff url(../../static/img/welcome/bank21.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(22) {
  background: #fff url(../../static/img/welcome/bank22.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(23) {
  background: #fff url(../../static/img/welcome/bank23.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(24) {
  background: #fff url(../../static/img/welcome/bank24.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(25) {
  background: #fff url(../../static/img/welcome/bank25.png) no-repeat center
    center;
  background-size: 45% !important;
}
.kh-bank div:nth-child(26) {
  background: #fff url(../../static/img/welcome/bank26.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(27) {
  background: #fff url(../../static/img/welcome/bank27.png) no-repeat center
    center;
}
.kh-bank div:nth-child(28) {
  background: #fff url(../../static/img/welcome/bank28.png) no-repeat center
    center;
}
.kh-bank div:nth-child(29) {
  background: #fff url(../../static/img/welcome/bank29.png) no-repeat center
    center;
}
.kh-bank div:nth-child(30) {
  background: #fff url(../../static/img/welcome/bank30.png) no-repeat center
    center;
}
.kh-bank div:nth-child(31) {
  background: #fff url(../../static/img/welcome/bank31.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(32) {
  background: #fff url(../../static/img/welcome/bank32.png) no-repeat center
    center;
}
.kh-bank div:nth-child(33) {
  background: #fff url(../../static/img/welcome/bank33.png) no-repeat center
    center;
  background-size: 45% !important;
}
.kh-bank div:nth-child(34) {
  background: #fff url(../../static/img/welcome/bank34.png) no-repeat center
    center;
}
.kh-bank div:nth-child(35) {
  background: #fff url(../../static/img/welcome/bank35.png) no-repeat center
    center;
}
.kh-bank div:nth-child(36) {
  background: #fff url(../../static/img/welcome/bank36.png) no-repeat center
    center;
  background-size: 60% !important;
}
.kh-bank div:nth-child(37) {
  background: #fff url(../../static/img/welcome/bank37.png) no-repeat center
    center;
  background-size: 45% !important;
}
.kh-bank div:nth-child(38) {
  background: #fff url(../../static/img/welcome/bank38.png) no-repeat center
    center;
}
.kh-bank div:nth-child(39) {
  background: #fff url(../../static/img/welcome/bank39.png) no-repeat center
    center;
  background-size: 45% !important;
}
.kh-bank div:nth-child(40) {
  background: #fff url(../../static/img/welcome/bank40.png) no-repeat center
    center;
} */
.kh-bank div {
  box-sizing: border-box;
  width: calc((100% - 50px) / 5);
  height: 80px;
  margin: 5px;
  padding: 20px 0;
  background-size: 50% !important;
  border-radius: 4px;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.04);
  box-sizing: content-box;
}
.kh-bank-m div {
  height: 40px;
}
.kh-mark {
  font-size: 12px;
  color: #999;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 50px;
}
.kh-bg-m {
  width: calc(100% - 20px);
  padding: 0 10px 30px;
}
.kh-bg-m .kh-w {
  width: 100%;
}
.kh-bg-m .kh-title {
  margin-bottom: 10px;
  font-size: 28px;
  margin-top: 50px;
}
.kh-bg-m .kh-comp {
  margin-top: 20px;
}
.kh-bg-m .kh-bank {
  margin-top: 20px;
}
.kh-bg-m .kh-comp div {
  width: calc((100% - 36px) / 3);
  background-size: 100% !important;
}
.kh-bg-m .kh-bank div {
  width: calc((100% - 36px) / 3);
  background-size: 60% !important;
}
.kh-bg-m .kh-comp div:nth-child(1) {
  /* background-size: 40% !important; */
}
.kh-bg-m .kh-comp div:nth-child(11) {
  /* background-size: 30% !important; */
}
.kh-bg-m .kh-comp div:nth-child(14) {
  /* background-size: 30% !important; */
}
.kh-bg-m .kh-comp div:nth-child(16) {
  background-size: 40% !important;
}
.kh-bg-m .kh-comp div:nth-child(19) {
  background-size: 30% !important;
}
.kh-bg-m .kh-comp div:nth-child(20) {
  background-size: 30% !important;
}
.kh-bg-m .kh-mark {
  text-align: left;
  padding-left: 5px;
}
/* 科技成果 */
/* 改 */
/* .cg-bg { width: 100%; background: #151e2c url(../../static/img/welcome/b-kj.png) no-repeat center center; padding: 80px 0 40px; } */
.cg-bg {
  padding: 160px 0 100px;
  width: 100%;
  background: url(../../static/img/welcome/b-kj2.png) no-repeat center center;
}
/* 改end */
.cg-main {
  width: 1200px;
  margin: auto;
}
.cg-title {
  font-size: 36px;
  color: #fff;
  text-align: center;
  transition: all 0.5s;
}
/* 改 */
/* .cg-time { color: #fff; opacity: 0.5; font-size: 16px; text-align: center; margin-top: 8px; transition: all 0.5s; } */
/* .cg-detail { display: flex; justify-content: space-between; align-items: center; transition: all 0.5s; flex-wrap: wrap; } */
/* .cg-item { width: calc(100% / 3); margin-bottom: 60px; text-align: center; } */
/* .cg-item .cg-outcon { color: #fff; font-size: 32px; } */
/* .cg-item .cg-unit { font-size: 30px; color: #fff; margin-left: -10px; } */
/* .cg-item div:nth-child(1) span:nth-child(1) { color: #fff; font-size: 58px; margin-bottom: 8px; } */
/* .cg-item div:nth-child(2) { color: #fff; opacity: 0.6; font-size: 18px; text-align: center; margin-top: 10px; } */
.cg-time {
  color: #999999;
  opacity: 0.5;
  font-size: 16px;
  text-align: center;
  margin-top: 100px;
  transition: all 0.5s;
}
.cg-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s;
  flex-wrap: wrap;
}
.cg-item {
  width: calc(100% / 3);
  margin-bottom: 60px;
  text-align: center;
}
.cg-item .cg-outcon {
  color: #fff;
  font-size: 32px;
}
.cg-item .cg-unit {
  font-size: 30px;
  font-family: Rubik;
  color: #333333;
  margin-left: -10px;
}
.cg-item .cg-unit1 {
  font-size: 58px;
}
.cg-item div:nth-child(1) span:nth-child(1) {
  font-family: Rubik;
  color: #333333;
  font-size: 58px;
  margin-bottom: 8px;
}
.cg-item div:nth-child(2) {
  font-family: PingFangSC;
  color: #666666;
  opacity: 0.6;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
/* 改end */
.cg-bg-m {
  padding: 0px;
  background-size: 100% 60%;
}
.cg-main-m {
  width: 100%;
  padding-top: 30px;
}
.cg-main-m .cg-time {
  padding-bottom: 30px;
  font-size: 16px;
}
.cg-main-m .cg-time {
  margin-top: 8px;
  padding-bottom: 30px;
  font-size: 16px;
}
.cg-main-m .cg-detail {
  margin-top: 0px;
  padding: 0 10px;
  box-sizing: border-box;
}
.cg-main-m .cg-item {
  width: 50%;
  margin-bottom: 60px;
}
.cg-main-m .cg-unit {
  font-size: 16px;
  margin-left: -5px;
}
.cg-item-m .cg-unit1 {
  font-size: 22px;
}
.cg-main-m .cg-item div:nth-child(1) {
  text-align: center;
}
/* .cg-main-m .cg-item div:nth-child(1) span:nth-child(1) { color: #fff; font-size: 28px; margin-bottom: 8px; } */
.cg-main-m .cg-item div:nth-child(1) span:nth-child(1) {
  color: #333;
  font-size: 28px;
  margin-bottom: 8px;
}
.cg-main-m .cg-item div:nth-child(2) {
  margin-top: 2px;
  font-size: 14px;
}
.cg-main-m .cg-item .cg-outcon {
  color: #fff;
  font-size: 22px;
}
/* 全屏播放 */
.bf-bg {
  position: fixed;
  width: 0%;
  height: 0%;
  top: 50%;
  left: 50%;
  z-index: 20;
  transition: all 0.3s;
}
.bf-close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: url(../../static/img/welcome/close.svg) no-repeat center center;
  z-index: 3;
  cursor: pointer;
  opacity: 0.6;
}
.bf-close-m {
  width: 25px;
  height: 25px;
  background-size: 100%;
  top: 20px;
  right: 20px;
}
.bf-close:hover {
  opacity: 1;
}
.bf-bg iframe {
  width: 100%;
  height: 100%;
}

.bg-video-m {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  height: 100%;
  background-color: #000;
  width: 100%;
  position: absolute;
}
</style>