
<template>
  <div>
    <!-- 背景图片 -->
    <div class="about-bg" :class="{ 'about-bg-m': mediaTer == 'Mobile' }">
      <h2 class="about-bgtext">关于我们</h2>
    </div>
    <!-- 导航栏 -->
    <div class="about-nav" :class="{ 'about-m': mediaTer == 'Mobile' }">
      <div class="w" :class="{ 'w-m': mediaTer == 'Mobile' }">
        <ul
          class="navbar"
          :class="{
            'navbar-m': mediaTer == 'Mobile',
          }"
        >
          <li @click="toLink('one')">
            <span :class="{ active: type == 'one' }">愿景使命 </span>
          </li>
          <li @click="toLink('two')">
            <span :class="{ active: type == 'two' }">企业介绍</span>
          </li>
          <!-- <li @click="toLink('three')">
                <span :class="{ active: type == 'three' }">{{ nav.item3 }}</span>
              </li> -->
          <li @click="toLink('four')">
            <span :class="{ active: type == 'four' }">发展历程 </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 愿景，使命，价值观 -->
    <template v-if="mediaTer != 'Mobile'">
      <div
        class="yj-bg"
        ref="about1"
        :class="flag['about1'] ? 'moveToTop' : 'old-place'"
      >
        <div class="yj-main">
            <p class="yj-title" >愿 景</p>
            <p style="line-height: 30px;font-size: 30px;text-align: center;color: #333;padding-top: 20px;">搭建领先的数字化平台 </p>
             <p style="line-height: 30px;font-size: 30px;text-align: center;color: #333;padding-top: 20px;"> 精准高效赋能金融人</p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="yj-bg-m">
        <div
          class="yj-main-m1"
          ref="about1"
          :class="[
            flag['about1'] ? 'moveToTop' : 'old-place'
            
          ]"
        ></div>
        <p class="yj-title" style="padding-top:40px">愿 景</p>
            <p style="line-height: 30px;font-size: 30px; text-align: center;color: #333;padding-top: 20px;">搭建领先的数字化平台 </p>
             <p style="line-height: 30px;font-size: 30px; text-align: center;color: #333;padding-top: 20px;"> 精准高效赋能金融人</p>
      </div>
    </template>

    <!-- 介绍 -->
    <div class="js-bg" :class="{ 'js-bg-m': mediaTer == 'Mobile' }">
      <div
        class="js-show"
        ref="about2"
        :class="flag['about2'] ? 'moveToTop' : 'old-place'"
      >
        <h3 class="js-title js-main">企业介绍</h3>
        <div class="js-main">
          <div class="js-left">
            <div class="js-con">
              <p>
                深圳市致远速联信息技术有限公司（简称：致远速联）成立于2010年，以公司创始人贾霄鹏先生为主的核心团队自1999年开始持续为中国证监会、深圳证券交易所等核心监管机构完成信息披露、业务监管等方面信息化系统开发；团队在无间断陪伴并参与中国证券市场信息化建设24年里，全程参与了制定证券领域的信息化国家标准及行业标准；是一家植根于证券金融领域的信息处理、加工和应用的数字化技术研发型企业。公司具备一支业务经验丰富、技术能力过硬的顶级团队；在商业报告标准化及自动化方面有着十几年的研发和处理经验；多项技术和产品在业内处于领先地位，客户均为证券金融领域的顶尖机构。
              </p>
              <p>
                在长时间服务资本市场过程中，致远速联逐渐构建了包含”金融数据库、监管规则库、分析模型库”的高质量高精度全量性资本市场数据库；自主研发了包含数据采集、数据分析核心引擎、计算机自主学习等数十项领先于行业的核心知识产权群；联合深圳证券通信有限公司、深圳证券信息有限公司(巨潮资讯），基于深证云构建资本市场数字化平台；针对金融业投资机构、研究机构、学术机构、监管部门、上市企业、中介服务结构和行业从业人员等不同客户的需求，为客户提供数字化系统开发、APl开放平台和BPS智能业务服务。
              </p>
              <!-- <p>
                致远速联通过20余年不间断的专业服务，拥有对业务场景、监管规则、风险模型、科技开发的融合型理解力。并在能力扩展的基础上，自建自研“证券数据库、监管规则库、风险评价模型库”，
                构建高质量高精度全量性资本市场数据库，通过多维度企业动态信息监控，全方位丰富企业画像。2020年公司再度携手深圳证券通信有限公司、深圳证券信息有限公司(巨潮资讯)，
                基于深证云构建资本市场数字化平台，为客户提供更为便捷、专业和安全的数字化服务，打造核心价值，提供高效解决方案，精准赋能金融人。
              </p> -->
            </div>
          </div>
          <div class="js-right" v-if="mediaTer != 'Mobile'"></div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div
      class="lc-bg"
      :class="{ 'lc-bg-m': mediaTer == 'Mobile' }"
      ref="fourBox"
    >
      <h3
        class="lc-title"
        ref="about3"
        :class="flag['about3'] ? 'moveToTop' : 'old-place'"
      >
        发展历程
      </h3>
      <div
        class="lc-main"
        ref="about4"
        :class="flag['about4'] ? 'moveToTop' : 'old-place'"
      >
        <div class="lc-main-in">
          <div
            class="lc-item"
            v-for="(item, index) in history"
            :key="'history' + index"
          >
            <div class="lc-item-left">{{ item.time }}</div>
            <div class="lc-item-main">
              <div class="lc-item-topline" v-if="index != 0"></div>
              <div
                class="lc-item-dot"
                :class="{ 'lc-item-dotact': index == 0 }"
              ></div>
              <div class="lc-item-line"></div>
            </div>
            <div class="lc-item-right">
              <div class="lc-item-title" v-html="item.title"></div>
              <div class="lc-item-msg" v-html="item.con"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "about",
  components: {},
  // metaInfo () {
  //   return {
  //     title: this.offLang.lang == 'EN' ? 'About Us - Linklogis Inc.': '关于我们 - 联易融科技集团',
  //     meta: [{                 // set meta
  //       name: 'keywords',
  //       content: '联易融数字科技集团有限公司,联易融,联易融数科,供应链金融,关于我们'
  //     }],
  //   }
  // },
  data() {
    return {
      //  nav: this.offLang.nevBar.aboutNav, //导航栏
      type:  "",
      flag: {},
      viewHeight: 0, //页面可视区域高度,
      historyIndex: 0, //默认显示的时间节点
      mediaTer: "", //终端类型
      //国际化
      // one: this.offLang.about.one,
      // two: this.offLang.about.two,
      // three: this.offLang.about.three
      history: [
      {
          time: "2022年8月",
          title: "与京东合作打造「京东云云税通」",
          con: "",
        },
        {
          time: "2022年5月",
          title: "为评级机构提供数据治理咨询服务",
          con: "",
        },
        {
          time: "2022年3月",
          title: "数字化投行「数字化托管工作台」与头部券商合作",
          con: "",
        },
        {
          time: "2021年10月",
          title: "加入第四届证标委WG35信息披露专家组",
          con: "",
        },
         {
          time: "2021年10月",
          title: "加入上市公司公告电子化规范标准起草组",
          con: "",
        }, {
          time: "2021年10月",
          title: "加入上市公司电子化规范（定期报告）标准起草组",
          con: "",
        }, {
          time: "2021年10月",
          title: "加入上市公司电子化规范（临时报告类）标准起草组",
          con: "",
        }, {
          time: "2021年7月",
          title: "数字化报告系列产品上线",
          con: "",
        }, {
          time: "2021年4月",
          title: "天津研发中心成立",
          con: "",
        }, {
          time: "2021年1月",
          title: "信用打分系统，行情加工平台上线",
          con: "",
        },
        {
          time: "2020年",
          title: "深交所固收填报系统",
          con: "（ABS年度资产监管报告、年度托管报告、信用风险监管报告）",
        },
        {
          time: "2019年",
          title: "深交所大数据画像",
          con: "（分类监管）",
        },
        {
          time: "2019年",
          title: "固收审核信息端",
          con: "（excel版）",
        },
        {
          time: "2018年",
          title: "上市公司电子化信息披露指标",
          con: "",
        },
        {
          time: "2017年",
          title: "证券期货行业数据模(SDOM)",
          con: "",
        },
        {
          time: "2017年",
          title: "中证报价专业版",
          con: "",
        },
        {
          time: "2016年",
          title: "北金所债券融资计划信息披露系统",
          con: "",
        },
        {
          time: "2015年",
          title: "深交所固收业务审核平台",
          con: "",
        },
        {
          time: "2014年",
          title: "银行间交易商协会债券信息披露服务平台",
          con: "",
        },
        {
          time: "2013年",
          title: "上市公司董秘工作平台",
          con: "",
        },
        {
          time: "2013年",
          title: "上市公司电子化信息披露指标",
          con: "",
        },
        {
          time: "2013年",
          title: "深交所智能指标平台",
          con: "",
        },
        {
          time: "2012年",
          title: "财政部与证监会的联合技术协调工作组",
          con: "",
        },
        {
          time: "2011年",
          title: "证监会IPO-XBRL报送系统",
          con: "",
        },
         {
          time: "2010年",
          title: "深交所上市公司定期报告制作系统",
          con: "（全文版）",
        },
         {
          time: "2010年",
          title: "分类监管系统",
          con: "",
        },
         {
          time: "2010年",
          title: "信息披露考核系统",
          con: "",
        },
         {
          time: "2009年",
          title: "深交所智能辅导系统",
          con: "",
        },
         {
          time: "2007年",
          title: "深交所上市公司定期报告制作系统",
          con: "（新会计准则）",
        },
         {
          time: "2006年",
          title: "深交所上市公司事前审查系统",
          con: "",
        },
        {
          time: "2004年",
          title: "深交所上市公司定期报告制作系统",
          con: "（XBRL）",
        },
        {
          time: "1999年",
          title: "第一代深交所上市公司定期报告制作系统",
          con: "",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      this.type = to.query.type;
       this.toLink(this.type)
    //  this.pageLink();
    },
  },
  created() {
    //获取访问环境
    this.mediaTer = this.getEnvironment();
  },
  mounted: function () {
    //页面锚点定位
    // this.pageLink();
    // this.type = "one";
    var num = 4;
    //页面初始化
    this.pageInit("about", num);
    //页面滚动动画
    this.pageScrollAmi("about", num);
    //页面尺寸变化
    this.pageSize();
    //发展历程计算
    this.setHistory();
     let type=this.$route.query.type
    if(type){
        this.type=type
       //页面锚点定位
     // this.pageLink();
     this.toLink(type)
    } else{
      this.type = "one";
    }
    // console.log(this.$route);
  },
  methods: {
    toLink(link) {
      this.type = link;
      console.log(document.documentElement.scrollTop);
      let topY = 0;
      if (link == "two") {
        this.getScrollIntoView("about2", "about2", 780, 760);
      } else if (link == "three") {
        
          this.getScrollIntoView("threeBox", "threeBox", 1930, 1762);
        
      } else if (link == "four") {
       
          this.getScrollIntoView("fourBox", "fourBox", 1488, 1438);
       
      } else {
        this.getScrollIntoView("about1", "about1", 240, 310);
      }
    },
    // 平滑方法
    getScrollIntoView(item1, item2, top1, top2) {
      if (this.mediaTer == "Mobile") {
        this.$refs[item1].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        setTimeout(() => {
          document.documentElement.scrollTop = top1;
        }, 450);
      } else {
        this.$refs[item2].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        setTimeout(() => {
          document.documentElement.scrollTop = top2;
        }, 450);
      }
    },
    //页面锚点定位
    pageLink: function () {
      var scroll = 0;
      var domRect = "";
      var topY = 0;
      if (this.type == "one") {
        topY = 380 - 70;
      } else if (this.type == "two") {
        topY = 380 + 440 - 70;
      } else if (this.type == "three") {
        var domThree = this.$refs.threeBox;
        domRect = domThree.getBoundingClientRect();
        topY = domRect.y - 70;
      } else if (this.type == "four") {
        var domFour = this.$refs.fourBox;
        domRect = domFour.getBoundingClientRect();
        topY = domRect.y - 70;
      }
      document.documentElement.scrollTop = topY;
    },
    //页面初始化
    pageInit: function (name, num) {
      var _this = this;
      _this.viewHeight = this.getViewHeight();
      //初始化dom信息
      for (let i = 1; i < num; i++) {
        _this.flag["dom" + i] = _this.$refs[name + "" + i];
        let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
        if (rectY < _this.viewHeight) {
          Vue.set(_this.flag, name + "" + i, true);
        } else {
          Vue.set(_this.flag, name + "" + i, false);
        }
        _this.$forceUpdate();
      }
    },
    //页面滚动动画
    pageScrollAmi: function (name, num) {
      var _this = this;
      window.onscroll = function () {
        for (let i = 1; i < num; i++) {
          let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
          if (rectY < _this.viewHeight) {
            Vue.set(_this.flag, name + "" + i, true);
          } else {
            Vue.set(_this.flag, name + "" + i, false);
          }
          _this.$forceUpdate();
        }

        //页面滚动高度
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > _this.$parent.scrollNum) {
          //向下滚动
          _this.$parent.hideFlag = true;
        } else {
          //向上滚动
          _this.$parent.hideFlag = false;
        }
        _this.$parent.scrollNum = scrollTop;
      };
    },
    //页面尺寸变化
    pageSize: function () {
      var _this = this;
      window.onresize = function () {
        _this.viewHeight = _this.getViewHeight();
      };
    },
    //发展历程计算
    setHistory: function () {
      this.historyIndex = 0;
    },
    //上一个里程碑
    toPre: function () {
      var curIndex = this.historyIndex;
      if (curIndex == 0) {
        return;
      }
      --curIndex;
      this.historyIndex = curIndex;
    },
    //下一个里程碑
    toNext: function () {
      var curIndex = this.historyIndex;
      if (curIndex == this.history.length - 1) {
        return;
      }
      ++curIndex;
      this.historyIndex = curIndex;
    },
  },
};
</script>
<style lang="less" scoped>
/* 背景 */
.about-bg {
  width: 100%;
  height: 380px;
  background: url(../../static/img/about/a-bg.jpg) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-bg-m {
  height: 300px; background: url(../../static/img/about/a-bg-m.png) no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}
.about-bgtext {
  text-indent: -888888px;
  width: 130px;
  height: 80px;
  background: url(../../static/img/about/bg-text.svg) no-repeat center center;
  background-size: 100%;
}

/* 愿景，使命，价值观 */
.yj-bg {
  width: 100%;
  height: 444px;
  background: url(../../static/img/about/bgSM.png) no-repeat center center;
  transition: all 0.5s;
}
.yj-main{width: 1200px;height: 100%;margin: auto;text-align: center; padding: 20px;}
.yj-title{font-size: 40px;
    color: #333;
    text-align: center;
    transition: all 0.5s; padding:20px 0; font-weight: bold;}
// .yj-main{width: 1200px;height: 100%;background: url(../../static/img/about/sm.svg) no-repeat center center;margin: auto;}
// .yj-main-en{background: url(../../static/img/about/sm-en.svg) no-repeat center center;background-size: 99%;}
.yj-bg-m {
  width: 100%;
  height: 500px;
  background: url(../../static/img/about/bgSM-M.png) no-repeat center center;
  transition: all 0.5s;
  background-size: 100% 100%;
}
//\ .yj-main-m1{width: 100%;height: 160px;background: url(../../static/img/about/sm1.svg) no-repeat center center;background-size: 70%;margin-top: 30px;transition: all 0.5s;}
// .yj-main-m1-en{background: url(../../static/img/about/sm1-en.svg) no-repeat center center;background-size: 60%;}
// .yj-main-m2{width: 100%;height: 160px;background: url(../../static/img/about/sm2.svg) no-repeat center center;background-size: 60%;transition: all 0.5s;}
// .yj-main-m2-en{background: url(../../static/img/about/sm2-en.svg) no-repeat center center;background-size: 60%;}
// .yj-main-m3{width: 100%;height: 140px;background: url(../../static/img/about/sm3.svg) no-repeat center center;background-size: 60%;transition: all 0.5s;}
// .yj-main-m3-en{background: url(../../static/img/about/sm3-en.svg) no-repeat center center;background-size: 60%;}

/* 联易融介绍 */
.js-bg {
  width: 100%;
  padding: 60px 0;
  position: relative; /*background-color: #fafafa*/
}
.js-show {
  transition: all 0.5s;
}
.js-main {
  width: 1200px;
  display: flex; /*height: 485px;*/
  justify-content: space-between;
  margin: auto;
  padding-bottom: 80px;
}
.js-left {
  width: 550px;
}
.js-left-en {
  width: 700px;
}
.js-left-en p {
  margin-right: 30px;
}
.js-title {
  font-size: 30px;
  color: #333;
  padding: 20px 0;
}
.js-con {
  font-size: 16px;
  color: #333;
  line-height: 30px;
}
.js-con p {
  margin-bottom: 16px;
}
.js-right {
  width: 550px; /*height: 100%;*/
  background: url(../../static/img/about/bg2.jpg) no-repeat center center;
  background-size: 100% 100%;
  border-radius: 6px;
}
.js-bg {
  padding: 80px 0 50px 0;
}
.js-bg-m {
  padding: 30px 0 30px 0;
}
.js-bg-m .js-main {
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}
.js-bg-m .js-left {
  width: 100%;
}
.js-bg-m .js-title {
  /* text-align: center; */
  justify-content: center;
  font-size: 24px;
}
/* 股东背景 */
.gd-bg {
  width: 100%;
  background-color: #fafafa;
  padding: 50px 0 100px;
}
.gd-box {
  width: 1200px;
  padding: 10px 0;
  margin: auto;
}
.gd-title {
  text-align: center;
  font-size: 30px;
  color: #333;
  margin-bottom: 60px;
  transition: all 0.5s;
}
.gd-mainnew {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}
.gd-mainrow2 {
  margin-top: -20px;
}
.gd-logobox {
  width: 160px;
  height: 160px;
  position: relative;
  margin: 0 40px;
}
.gd-logobox div:nth-child(1) {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 160px;
  height: 160px;
  transform: rotate(45deg);
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}
.gd-logobox div:nth-child(2) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  cursor: pointer;
  transition: all 0.3s;
}
.gd-logobox:hover div:nth-child(1) {
  box-shadow: 10px 10px 17px 2px rgba(0, 0, 0, 0.16);
}
// .gd1{background: url(../../static/img/about/g-ten.svg) no-repeat center center;}
// .gd2{background: url(../../static/img/about/g-cit.svg) no-repeat center center;}
// .gd3{background: url(../../static/img/about/g-zxg.svg) no-repeat center center;}
// .gd4{background: url(../../static/img/about/g-gic.svg) no-repeat center center;}
// .gd5{background: url(../../static/img/about/g-stand.svg) no-repeat center center;}
// .gd6{background: url(../../static/img/about/g-bai.svg) no-repeat center center;}
// .gd7{background: url(../../static/img/about/g-zsj.svg) no-repeat center center;}
// .gd8{background: url(../../static/img/about/g-sky.svg) no-repeat center center;}
// .gd9{background: url(../../static/img/about/g-fh.svg) no-repeat center center;}
// .gd10{background: url(../../static/img/about/g-glp.svg) no-repeat center center;}
// .gd11{background: url(../../static/img/about/g-wel.svg) no-repeat center center;}
.gd-bg-m {
  padding: 50px 0 60px;
}
.gd-bg-m .gd-box {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.gd-bg-m .gd-title {
  margin-bottom: 30px;
  font-size: 24px;
}
.gd-bg-m .gd-logobox {
  width: 55px;
  height: 55px;
  margin: 0 15px;
}
.gd-bg-m .gd-logobox div:nth-child(1) {
  width: 100%;
  height: 100%;
}
.gd-bg-m .gd-logobox div:nth-child(2) {
  background-size: 80%;
}
.gd-bg-m .gd-mainrow2 {
  margin-top: -10px;
}

/* 发展历程 */
.lc-bg {
  width: 1050px;
  transition: all 0.5s;
  margin: auto;
  padding-top: 70px;
}
.lc-title {
  font-size: 30px;
  color: #333;
  text-align: center;
  transition: all 0.5s;
}
.lc-main {
  display: flex;
  margin: auto;
  justify-content: space-between;
  transition: all 0.5s;
  flex-direction: column;
  padding-top: 50px;
  overflow: hidden;
}
.lc-main-in {
  width: calc(100% + 0px);
  height: 560px;
  overflow: auto;
}
.lc-item {
  display: flex;
}
.lc-item-left {
  width: 250px;
  text-align: center;
  color: #008dff;
  font-size: 30px;
}
.lc-item-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 40px 0 0px;
  width: 25px;
}
.lc-item-dot {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 3px solid #d8d8d8;
  background-color: #fafafa;
  border-radius: 50%;
}
.lc-item-dotact {
  width: 24px;
  height: 24px;
  border: 5px solid #008dff;
  margin-top: 7px;
}
.lc-item-topline {
  width: 3px;
  background-color: #d8d8d8;
  height: 10px;
}
.lc-item-line {
  width: 3px;
  background-color: #d8d8d8;
  height: calc(100% - 30px);
}
.lc-item-right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
  margin-bottom: 70px;
}
.lc-item-title {
  font-size: 30px;
  color: #333;
  margin-bottom: 10px;
}
.lc-item-msg {
  font-size: 20px;
  color: #666;
}

// .lc-main .lc-left{width: 80px;height: 80px;background:url(../../static/img/about/i-left-def.svg) no-repeat center center;background-size: 100%;cursor: pointer;}
// .lc-left:hover{background: url(../../static/img/about/i-left-hover.svg) no-repeat center center;}
// .lc-main .lc-leftnot{background: url(../../static/img/about/i-left-dis.svg) no-repeat center center;cursor: default;background-size: 100%;}
.lc-bg-m {
  width: 100%;
  padding: 30px 10px 0;
  box-sizing: border-box;
}
.lc-bg-m .lc-main {
  padding-top: 30px;
  padding-bottom: 30px;
}
.lc-bg-m .lc-title {
  font-size: 24px;
}
.lc-bg-m .lc-item-left {
  width: 90px;
  font-size: 16px;
}
.lc-bg-m .lc-item-dot {
  width: 16px;
  height: 16px;
  border: 2px solid #d8d8d8;
}
.lc-bg-m .lc-item-dotact {
  width: 20px;
  height: 20px;
  border: 3px solid #008dff;
  margin-top: 2px;
}
.lc-bg-m .lc-item-topline {
  width: 2px;
  height: 4px;
}
.lc-bg-m .lc-item-line {
  width: 2px;
  height: calc(100% - 20px);
}
.lc-bg-m .lc-item-main {
  margin: 0px 8px 0 0;
}
.lc-bg-m .lc-item-right {
  width: calc(100% - 150px);
  margin-bottom: 40px;
}
.lc-bg-m .lc-item-title {
  font-size: 20px;
}
.lc-bg-m .lc-item-msg {
  font-size: 16px;
}
/* 历程-信息 */
/*.lc-main .lc-mid{display: flex;width: 1100px;}
    .lc-time{font-size: 40px;color: #008DFF;width: 300px;padding-top: 30px;}
    .lc-info{width: calc(100% - 300px)}
    .lc-info div:nth-child(1){font-size: 36px;color: #333;margin-bottom: 10px}
    .lc-info div:nth-child(2){font-size: 24px;color: #666;}
    .lc-main .lc-right{width: 80px;height: 80px;background:url(../../static/img/about/i-right-def.svg) no-repeat center center;background-size: 100%;cursor: pointer;}
    .lc-right:hover{background: url(../../static/img/about/i-right-hover.svg) no-repeat center center;}
    .lc-main .lc-rightnot{background: url(../../static/img/about/i-right-dis.svg) no-repeat center center;cursor: default;background-size: 100%;}*/
/*.lc-bg-m .lc-mid{width: calc(100% - 100px);}
    .lc-bg-m .lc-left{width: 40px;height: 40px;background-size: 100%;}
    .lc-bg-m .lc-right{width: 40px;height: 40px;background-size: 100%;}
    .lc-bg-m .lc-time{font-size: 18px;width: 100px;padding-top: 0px;display: flex;justify-content: center;align-items: center;}
    .lc-bg-m .lc-info{width: calc(100% - 120px);margin-left: 10px;}
    .lc-bg-m .lc-info div:nth-child(1){font-size: 16px;}
    .lc-bg-m .lc-info div:nth-child(2){font-size: 12px;}*/
/* 滚动条美化 */
::-webkit-scrollbar-track-piece {
  /*滚动条凹槽的颜色，还可以设置边框属性*/
  background-color: #f4f4f4;
  border-radius: 8px;
}
::-webkit-scrollbar {
  /* 滚动条宽度 */
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  /*外层轨道*/
  /*border-left: 1px solid #d9d9d9;*/
  /*width: 20px;*/
}
::-webkit-scrollbar-thumb {
  /*滚动条的设置*/
  background-color: #e7e7e7;
  /*background-clip:padding-box;*/
  border-radius: 8px;
  min-height: 28px;
}
::-webkit-scrollbar-button {
}
::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}
/* 导航栏 */
/* 版心 */
.w {
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}
.w-m {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}
h3 {
  font-weight: 400;
}
// PC端
.about-nav {
  position: sticky;
  z-index: 1;
  top: 70px;
  box-sizing: border-box;
  width: 100%;
  background-color: #f7f7f7;
  // position: relative;
  .navbar {
    display: flex;
    align-items: center;
    li {
      margin-right: 48px;
      text-align: center;
      font-size: 16px;
      color: #666666;
      font-family: PingFangSC, PingFangSC-Regular;
      cursor: pointer;
      span {
        display: inline-block;
        line-height: 52px;
      }
    }
    li span.active {
      color: #008dff;
      border-bottom: 2px solid #008dff;
    }
  }
}

.about-nav .navbar {
  display: flex;
  align-items: center;
}
.about-nav .navbar li {
  margin-right: 48px;
  text-align: center;
  font-size: 16px;
  color: #666666;
  font-family: PingFangSC, PingFangSC-Regular;
  cursor: pointer;
}
.about-nav .navbar li span {
  display: inline-block;
  line-height: 52px;
}
.about-nav .navbar li span.active {
  color: #008dff;
  border-bottom: 2px solid #008dff;
}

.about-m {
  top: 59px;
}
// 导航栏移动端
.about-nav .w-m {
  padding-right: 100px;
  overflow: auto;
}
.about-nav .navbar-m {
  justify-content: space-around;
  width: 350px;
  li {
    margin-right: 0px;
  }
}
.about-nav .w-m {
  box-sizing: border-box;
  .navbar-m-e {
    width: 500px;
    overflow: auto;
    li {
      font-size: 12px;
    }
  }
  // .navbar-m-e.navbar-m {
  //   padding-right: 80px;
  // }
}
</style>