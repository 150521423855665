<style scoped >
/* 背景 */
.cont-bg {
  width: 100%;
  height: 380px;
  background: url(../../static/img/contact/concat-bg.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 38px;
}

.cont-bg-m {
  height: 300px;
  background: url(../../static/img/contact/concat-bg-m.png) no-repeat center center;
}

.cont-bgtext {
  text-indent: -888888px;
  width: 130px;
  height: 80px;
  background: url(../../static/img/contact/concat-bg-text.svg) no-repeat center center;
  background-size: 100%;
}

.cont-bgtext-en {
  /* background: url(../../static/img/contact/bg-text-en.svg) no-repeat center
     center; */
  background-size: 100%;
  width: 220px;
  height: 150px;
}

/* 联系电话 */
.cont-tel {
  width: 1200px;
  margin: 0px auto 40px;
  padding-top: 60px;
}

.tel-item {
  height: 120px;
  transition: all 0.5s;
}

.tel-line {
  width: 100%;
  display: flex;
  align-items: center;
}

.tel-line1 {
  width: 100px;
  height: 1px;
  background-color: #008dff;
}

.tel-line2 {
  width: calc(100% - 100px);
  height: 1px;
  background-color: #e2e2e2;
}

.tel-box {
  display: flex;
  margin-top: 20px;
}

.tel-lab {
  width: 140px;
  color: #333;
  font-size: 26px;
  margin-right: 20px;
}

.tel-lab-en {
  width: 300px;
}

.tel-right {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}

.tel-emall {
  font-size: 14px;
  color: #999;
  margin-bottom: 8px;
}

.tel-val {
  font-size: 24px;
  color: #333;
}

.cont-tel-m {
  width: calc(100% - 50px);
}

.cont-tel-m .tel-item {
  height: 100px;
}

.cont-tel-m .tel-line1 {
  width: 80px;
}

.cont-tel-m .tel-line2 {
  width: calc(100% - 80px);
}

.cont-tel-m .tel-box {
  margin-top: 15px;
}

.cont-tel-m .tel-lab {
  width: 90px;
  font-size: 20px;
}

.cont-tel-m .tel-lab-en {
  width: 110px;
}

.cont-tel-m .tel-val {
  font-size: 18px;
}

.cont-tel-m .tel-val a {
  font-size: 18px;
  color: #333;
}

/* 地址 */
.cont-main {
  width: 1200px;
  margin: 0px auto 0;
  background-color: #fff;
  /*box-shadow: 0px 3px 8px 2px rgba(0,0,0,0.06);padding: 20px 40px;*/
  box-sizing: border-box;
}

.cont-item {
  padding: 30px 40px;
  transition: all 0.5s;
  border-radius: 8px;
}

.cont-item:nth-child(even) {
  background-color: #fafafa;
}

.cont-name {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
  height: 50px;
  display: flex;
  align-items: center;
}

.cont-name div {
  /* background: url(../../static/img/contact/i-ads.svg) no-repeat left center; */
  width: 26px;
  height: 30px;
}

.cont-det {
  font-size: 18px;
  color: #666;
  padding-bottom: 4px;
}

.cont-main-m {
  width: calc(100% - 40px);
  padding: 0px 0 40px 0;
}

.cont-main-m .cont-item {
  padding: 20px 30px;
}

.cont-main-m .cont-name {
  font-size: 20px;
  height: 40px;
}

.cont-main-m .cont-name div {
  background-size: 80%;
  width: 18px;
}

.cont-main-m .cont-det {
  font-size: 14px;
}

/* 分隔线 */
.cont-line {
  width: calc(100% - 40px);
  height: 0px;
  border-top: 1px dashed #e2e2e2;
  margin: 0 20px 2px;
  box-sizing: border-box;
}

.cont-line:nth-child(odd) {
  margin-top: 60px;
}

/* 意见反馈 */
.fk-box {
  width: 800px;
  margin: auto;
  padding: 40px 0;
  transition: all 0.5s;
}

.fk-title {
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.fk-box textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 12px;
}

.fk-box input {
  width: 100%;
  height: 40px;
  margin-bottom: 12px;
}

.fk-img {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-end;
}

.fk-imgitem {
  width: 96px;
  height: 96px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
}

.fk-imgitem:hover {
  border: 1px solid #008dff;
}

.fk-imgadd {
  width: 100%;
  height: 100%;
  /* background: url(../../static/img/contact/add.svg) no-repeat center center; */
}

.fk-imgtext {
  font-size: 14px;
  color: #999;
}

.fk-btn {
  width: 200px;
  height: 40px;
  border: 1px solid #008dff;
  color: #008dff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 10px;
  transition: all 0.3s;
  cursor: pointer;
}

.fk-btn:hover {
  background-color: #008dff;
  color: #fff;
}

.fk-box-m {
  width: 100%;
  padding: 20px;
}

.fk-box-m .fk-title {
  font-size: 26px;
  margin-bottom: 10px;
}

.fk-box-m .fk-btn {
  width: 100%;
  height: 45px;
  font-size: 18px;
}

h3 {
  font-weight: 400;
}

/* 导航栏 */
/* 版心 */
.w {
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}

.w-m {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}

/* // PC端 */
.contact-nav {
  position: sticky;
  top: 70px;
  z-index: 3;
  box-sizing: border-box;
  width: 100%;
  background-color: #f7f7f7;

}

.contact-nav .navbar {
  display: flex;
  align-items: center;
}

.contact-nav li {
  margin-right: 48px;
  text-align: center;
  font-size: 16px;
  color: #666666;
  font-family: PingFangSC, PingFangSC-Regular;
  cursor: pointer;
}

.contact-nav li span {
  display: inline-block;
  line-height: 52px;
}

.contact-nav li span.active {
  color: #008dff;
  border-bottom: 2px solid #008dff;
}

/* // 导航栏移动端 */
.contact-m {
  top: 59px;

}

.contact-m .w-m {
  overflow: auto;
}

.contact-m .navbar-m {
  width: 190px;
}

.contact-m .navbar-m li {
  margin-right: 30px;
}

.contact-m .navbar-m-e {
  width: 300px;
}

.contact-m .navbar-m-e li {
  font-size: 12px;
}

.cont-join {
  width: 1200px;
  margin: 0px auto 0;
  background-color: rgb(245, 246, 250);
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.06);
  padding: 20px 40px;
  box-sizing: border-box;
}

.content .filter {
  background-color: #fff;
  margin: 20px 0;
  padding: 0 32px;
  border-radius: 4px;
  position: relative;
}

.content .filter .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 62px;
}


.content .filter .item .filed {
  font-size: 14px;
  margin-right: 40px;
  font-weight: 700;
}

.job-list .el-radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


/deep/.el-radio-button__inner {
  border: none !important;
}

.table-menu {
  cursor: pointer;
}

.flex-jstart {
  display: flex;
  justify-content: flex-start;
}

.flex-a {
  display: flex;
  align-items: center;
}

.paddinglr4rem {
  padding: 0 4rem;
}

.table-menu-item:nth-child(1) {
  width: 25%;
  text-align: left;
}

.table-menu-item:nth-child(2) {
  width: 25%;
  text-align: center;
}

.table-menu-item:nth-child(3) {
  width: 25%;
  text-align: center;
}

.table-menu-item:nth-child(4) {
  width: 25%;
  text-align: center;
}

.padding15 {
  padding: 15px;
}

.context17 {
  font-size: 17px;
  line-height: 1.6;
  color: #6f6f6f;
  /* font-weight: 600; */
}

.theadbg {
  background-color: rgb(9, 135, 255)
}

.fontwhite {
  color: #fff;
}

/* é€æ˜Žåº¦ */
.opacity6 {
  opacity: 0.6;
}

/*å›¾æ ‡å‘ä¸Šæ—‹è½¬*/
.transform_up {
  transition-duration: .5s;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

/*å›¾æ ‡å‘ä¸‹æ—‹è½¬*/
.transform_down {
  transition-duration: .5s;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

/*å›¾ç‰‡æ—‹è½¬æ•ˆæžœ*/
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(180deg);
  }
}

@-webkit-keyframes rotation1 {
  from {
    -webkit-transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation0 {
  from {
    -webkit-transform: rotate(180deg);
  }

  to {
    -webkit-transform: rotate(0);
  }
}

.animation0 {
  -webkit-animation: rotation0 0.6s ease;
  -o-animation: rotation0 0.6s ease;
  -moz-animation: rotation0 0.6s ease;
  animation: rotation0 0.6s ease;
  animation-fill-mode: forwards;
  /* ä¸å¾ªçŽ¯è½¬åŠ¨ */
}

.animation {
  -webkit-animation: rotation 0.6s ease;
  -o-animation: rotation 0.6s ease;
  -moz-animation: rotation 0.6s ease;
  animation: rotation 0.6s ease;
  animation-fill-mode: forwards;
  /* ä¸å¾ªçŽ¯è½¬åŠ¨ */
}

.anima1 {
  -webkit-animation: rotation1 0.6s ease;
  -o-animation: rotation1 0.6s ease;
  -moz-animation: rotation1 0.6s ease;
  animation: rotation1 0.6s ease;
  animation-fill-mode: forwards;
  /* ä¸å¾ªçŽ¯è½¬åŠ¨ */
}

.listItemDetail {
  display: none
}
.listItem{
  border-bottom: 1px solid rgba(224, 212, 212, 0.8);
}
.table-menu-item-detail{
 
    /* padding: .2rem 15px; */
}

.table-menu-item-detail p{
  line-height: 2rem;
}

.navtitle-h3 {
    font-size: 18px;
    color: #535e74;
    font-weight: bold;
    line-height: 1.5;
}
.textcenter {
    text-align: center;
}

.divcon /deep/p{
  /* background-color: #FFFFFF;
    color: #000000; */
    font-family: Verdana,Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 1.8;
}
</style>

<template>
  <div class="contact-box">
    <!-- 背景 -->
    <div class="cont-bg" :class="{ 'cont-bg-m': mediaTer == 'Mobile' }">
      <h2 class="cont-bgtext">联系我们</h2>
    </div>
    <!-- 导航栏 -->
    <div class="contact-nav" :class="{ 'contact-m': mediaTer == 'Mobile' }">
      <div class="w" :class="{ 'w-m': mediaTer == 'Mobile' }">
        <ul class="navbar" :class="{
          'navbar-m': mediaTer == 'Mobile',
        
        }">
          <li @click="toLink('cont1')">
            <span :class="{ active: type == 'cont1' }">联系方式</span>
          </li>
          <li @click="toLink('twoBox')">
            <span :class="{ active: type == 'twoBox' }">联系地址</span>
          </li>
          <li @click="toLink('threeBox')">
            <span :class="{ active: type == 'threeBox' }">加入我们</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 联系电话 -->
    <div class="cont-tel" :class="{ 'cont-tel-m': mediaTer == 'Mobile' }">
      <div class="tel-item" ref="cont1" :class="flag['cont1'] ? 'moveToTop' : 'old-place'">
        <div class="tel-line">
          <div class="tel-line1"></div>
          <div class="tel-line2"></div>
        </div>
        <div class="tel-box">
          <div class="tel-lab">
            联系电话
          </div>
          <div class="tel-right">
            <div class="tel-emall">周一至周五10:00-18:00</div>
            <div class="tel-val" v-if="mediaTer == 'Mobile'">
              <a href="tel:0755-8239 8100">+86 0755-8239 8100</a>
            </div>
            <div class="tel-val" v-else>+86 0755-8239 8100</div>
          </div>
        </div>
      </div>
      <div class="tel-item" ref="cont2" :class="flag['cont2'] ? 'moveToTop' : 'old-place'">
        <div class="tel-line">
          <div class="tel-line1"></div>
          <div class="tel-line2"></div>
        </div>
        <div class="tel-box">
          <div class="tel-lab">
            联系邮箱
          </div>
          <div class="tel-right">
            <div class="tel-emall">E-mail</div>
            <div class="tel-val">zysl@genesysinfo.net</div>
          </div>
        </div>
      </div>

      <div class="tel-line">
        <div class="tel-line1"></div>
        <div class="tel-line2"></div>
      </div>
    </div>
    <!-- 地址 -->
    <div class="cont-main" :class="{ 'cont-main-m': mediaTer == 'Mobile' }" ref="twoBox">
      <div class="cont-item" ref="cont3" :class="flag['cont5'] ? 'moveToTopM' : 'old-placeM'">
        <h3 class="cont-name">致远速联深圳总部</h3>
        <div class="cont-det">地址：罗湖区宝安北路1007号HALO广场三期4层401-02单元</div>
      </div>
      <div class="cont-item" ref="cont4" :class="flag['cont6'] ? 'moveToTopM' : 'old-placeM'">
        <h3 class="cont-name">致远速联天津研发中心</h3>
        <div class="cont-det">地址：天津市西青区新技术产业园区梓苑路6号，普天大厦东塔403</div>
      </div>


    </div>

    <!-- 加入我们 -->
    <div class="cont-join" :class="{ 'cont-join-m': mediaTer == 'Mobile' }" ref="threeBox">
      <div class="job-list">
        <div class="content">
          <div class="filter">
            <div class="item" style="border-bottom: dashed 1px;border-color: #e0d4d4cc;">
              <div class="filed">工作地点</div>
              <el-radio-group v-model="place" size="mini" @change="btnsearch">
                <el-radio-button label="全部"></el-radio-button>
                <el-radio-button v-for="item in workplace" :label="item.value">{{ item.label }}</el-radio-button>

              </el-radio-group>
            </div>
            <div class="item">
              <div class="filed">职位类别</div>
              <el-radio-group v-model="position" size="mini" @change="btnsearch">
                <el-radio-button label="全部"></el-radio-button>
                <el-radio-button label="技术研发类"></el-radio-button>
                <el-radio-button label="金融业务类"></el-radio-button>
                <el-radio-button label="项目产品类"></el-radio-button>
                <el-radio-button label="职能管理类"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="table-menu flex-jstart flex-a theadbg paddinglr4rem">
            <div class="table-menu-item padding15">
              <h3 class="context17 fontwhite">岗位名称</h3>
            </div>
            <div class="table-menu-item">
              <h3 class="context17 fontwhite">职位类别</h3>
            </div>
            <div class="table-menu-item">
              <h3 class="context17 fontwhite">工作地点</h3>
            </div>

            <div class="table-menu-item">
              <h3 class="context17 fontwhite">详情</h3>
            </div>
          </div>
          <div class="jobList">
            <ul>
              <li class="border-bottom" v-for="(item, index) in jobdata" :key="'job' + index">
                <div class="listItem" @click="btnClickli($event)">
                  <div class="table-menu flex-jstart flex-a bg-white pointer paddinglr4rem">
                    <div class="table-menu-item padding15">
                      <h3 class="context17" style="color: #4183fd;">{{ item.conTitle }}</h3>
                    </div>
                    <div class="table-menu-item">
                      <h3 class="context17">{{ item.chTitle }}</h3>
                    </div>
                    <div class="table-menu-item">
                      <h3 class="context17">{{ item.parentChTitle }}</h3>
                    </div>
                    <div class="table-menu-item">
                      <h3 class="transform180"><img :src="bottom" alt="" srcset=""></h3>
                    </div>
                  </div>
                </div>
                <div class="listItemDetail bg-f2f2f2 w100 border-top paddinglr4rem" :style="{display:index==0?'block':'none'}">
                  <div class="table-menu-item-detail">
                    <div class="divcon padding15" v-html="item.conText"></div>
                  </div>
                </div>
              </li>
               <!-- <li><h3 class="mtop20 textcenter navtitle-h3">简历投递邮箱：xhliu@genesysinfo.net</h3></li> -->
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import bottom from '@/static/img/contact/bottom.png'
import axios from "axios";
const apiDebugUrl = process.env.NODE_ENV === "development"
  ? 'https://ea.cmdp.cn/'
  : "https://a.cmdp.cn/";
export default {
  components: {},
  props: ["param"],
  data() {
    return {
      // nav: this.offLang.nevBar.contactNav, //导航栏
      type: '',
      typeClickFlag: true, // 防止tab栏多次触发
      flag: {},
      viewHeight: 0, //页面可视区域高度
      msg: "",
      name: "",
      telphone: "",
      email: "",
      mediaTer: "", //终端类型
      place: "全部",
      position: '全部',
      bottom: bottom,
      jobdata: [
        { id: '001', place: '深圳市', name: '项目助理', position: '项目产品类', detail: '项目产品类' },
        { id: '002', place: '天津市', name: '高级运维', position: '技术研发类', detail: '技术研发类' },
      ],
      channelurl: apiDebugUrl + "cmss/v1/op/channel/list",
      contenturl: apiDebugUrl + "cmss/v1/op/content/list",
      workplace: [],
      workalltype: [],
      searchworktype: [],
      addworktype: [],
    };
  },
  watch: {
    // $route(to, from) {
    //   this.type = to.query.type;
    //   this.pageLink();
    // },
  },
  created() {
    //获取访问环境
    this.mediaTer = this.getEnvironment();
    document.documentElement.scrollTop = 0;
  },
  mounted: function () {
    //页面锚点定位
    // this.pageLink();
    //页面初始化
    this.pageInit("cont", 5);
    //页面滚动动画
    this.pageScrollAmi();
    //页面尺寸变化
    this.pageSize();
    let type = this.$route.query.type
    if (type) {
      this.type = type
      //页面锚点定位
      // this.pageLink();
      this.toLink(type)
    } else {
      this.type = "cont1";
    }
    this.getworkplace();
    this.gettableData();
    // this.getType();
  },
  methods: {
    // 获取type
    getType() {
      if (this.$refs.cont3.getBoundingClientRect().top <= -125) {
        this.type = "threeBox";
      }
      else if (this.$refs.cont3.getBoundingClientRect().top <= 300 && this.$refs.cont3.getBoundingClientRect().top > -165) {
        this.type = "twoBox";
      } else {
        this.type = "cont1";
      }
      console.log(this.$refs.cont3.getBoundingClientRect().top)
    },
    toLink(link) {
      if (this.typeClickFlag) {
        this.getScrollIntoView(link);
      }
      this.typeClickFlag = false;
    },
    // 方法
    getScrollIntoView(ele) {
      let timeID = "";
      try {
        clearInterval(timeID);
      } catch (err) { }
      timeID = setInterval(() => {
        let dom = this.$refs[ele];
        let top = Math.ceil(dom.getBoundingClientRect().top);
        if (top > 191) {
          document.documentElement.scrollTop += 30;
        } else if (top <= 191 && top >= 0) {
          this.getType();
          this.pageLink();
          clearInterval(timeID);
        } else {
          document.documentElement.scrollTop -= 30;
        }
      }, 1);
    },
    //页面锚点定位
    pageLink() {
      if (this.mediaTer == "Mobile") {
        if (this.type == "cont1") {
          document.documentElement.scrollTop = 270;
        } else if (this.type == "twoBox") {
          document.documentElement.scrollTop = 711;
        } else if (this.type == "threeBox") {
          document.documentElement.scrollTop = 800;
        }
      } else {
        if (this.type == "cont1") {
          document.documentElement.scrollTop = 350;
        } else if (this.type == "twoBox") {
          document.documentElement.scrollTop = 871;
        }
        else if (this.type == "threeBox") {
          document.documentElement.scrollTop = 960;
        }
      }
      this.typeClickFlag = true;
    },
    //页面初始化
    pageInit: function (name, num) {
      var _this = this;
      _this.viewHeight = this.getViewHeight();
      //初始化dom信息
      for (let i = 1; i < num; i++) {
        _this.flag["dom" + i] = _this.$refs[name + "" + i];
        let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
        if (rectY < _this.viewHeight) {
          Vue.set(_this.flag, name + "" + i, true);
        } else {
          Vue.set(_this.flag, name + "" + i, false);
        }
        _this.$forceUpdate();
      }
    },
    //页面滚动动画
    pageScrollAmi: function () {
      var _this = this;
      window.onscroll = function () {
        _this.pageInit("cont", 5);
        //页面滚动高度
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;

        if (_this.typeClickFlag) {
          _this.getType();
        }
        _this.$parent.scrollNum = scrollTop;
      };
    },
    //页面尺寸变化
    pageSize: function () {
      var _this = this;
      window.onresize = function () {
        _this.viewHeight = _this.getViewHeight();
      };
    },
    //提交意见
    submit: function () {
      var msg = this.msg || "";
      var name = this.name || "";
      var telphone = this.telphone || "";
      var email = this.email || "";
      if (!msg) {
        this.$llsMessage({ type: "warn", text: "请填写您的建议" });
        var domMsg = this.$refs.msg;
        domMsg.focus();
        return;
      }
      this.$llsMessage({ type: "suc", text: "提交成功" });
    },

    btnClickli(event) {
      var menu = $(event.currentTarget).next(".listItemDetail");
      if (menu.is(":hidden")) {
        $(event.currentTarget).find(".transform180").addClass("animation").removeClass("anima1").parents("li").siblings("li").find(".transform180").removeClass("animation")
      }
      else {
        $(event.currentTarget).find(".transform180").addClass("anima1").removeClass("animation");
      }
      $(event.currentTarget).next(".listItemDetail").slideToggle(200).parents("li").siblings("li").find("div.listItemDetail").slideUp(200);
    },
    //获取工作地点
    getworkplace() {
      let para = {
        pageParam: {
          id: "-1",
          chStatus: 1
        }
      }
      axios.request({
        url: this.channelurl,
        method: "POST",
        data: para
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            let workplace = []
            let worktype = []
            res.data.data.map(function (d) {
              if (d.parentid == "-1") {
                workplace.push({ label: d.chTitle, value: d.id })
              } else {
                worktype.push(d)
              }
            })
            this.workplace = workplace;
            this.workalltype = worktype;
          } else {
            this.$message.error(res.data.msg)
          }
        }
        else {
          this.$message({ message: '请求出错', type: 'error' })
          // alert('请求出错')
        }
        console.log(res);
      })
    },
    gettableData() {
      let url = `${this.contenturl}`;
      let para = {
        paged: false,
        pageParam: {
          chTitle: this.position=='全部'?'':this.position,
          parentid: this.place=='全部'?'':this.place,
          conStatus:1
        }
      }
      axios.request({
        url:url,
         method: "POST",
        data: para
      }).then(res=>{
         if (res.status == 200) {
          if (res.data.code == 200) {
            this.jobdata=res.data.data;
          } else {
            this.$message.error(res.data.msg)
          }
        }
        else {
          this.$message({ message: '请求出错', type: 'error' })
          // alert('请求出错')
        }
      })
    },
    btnsearch(){
      this.gettableData();
    }
  },
};
</script>



