var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"about-bg",class:{ 'about-bg-m': _vm.mediaTer == 'Mobile' }},[_c('h2',{staticClass:"about-bgtext"},[_vm._v("关于我们")])]),_c('div',{staticClass:"about-nav",class:{ 'about-m': _vm.mediaTer == 'Mobile' }},[_c('div',{staticClass:"w",class:{ 'w-m': _vm.mediaTer == 'Mobile' }},[_c('ul',{staticClass:"navbar",class:{
          'navbar-m': _vm.mediaTer == 'Mobile',
        }},[_c('li',{on:{"click":function($event){return _vm.toLink('one')}}},[_c('span',{class:{ active: _vm.type == 'one' }},[_vm._v("愿景使命 ")])]),_c('li',{on:{"click":function($event){return _vm.toLink('two')}}},[_c('span',{class:{ active: _vm.type == 'two' }},[_vm._v("企业介绍")])]),_c('li',{on:{"click":function($event){return _vm.toLink('four')}}},[_c('span',{class:{ active: _vm.type == 'four' }},[_vm._v("发展历程 ")])])])])]),(_vm.mediaTer != 'Mobile')?[_c('div',{ref:"about1",staticClass:"yj-bg",class:_vm.flag['about1'] ? 'moveToTop' : 'old-place'},[_vm._m(0)])]:[_c('div',{staticClass:"yj-bg-m"},[_c('div',{ref:"about1",staticClass:"yj-main-m1",class:[
          _vm.flag['about1'] ? 'moveToTop' : 'old-place'
          
        ]}),_c('p',{staticClass:"yj-title",staticStyle:{"padding-top":"40px"}},[_vm._v("愿 景")]),_c('p',{staticStyle:{"line-height":"30px","font-size":"30px","text-align":"center","color":"#333","padding-top":"20px"}},[_vm._v("搭建领先的数字化平台 ")]),_c('p',{staticStyle:{"line-height":"30px","font-size":"30px","text-align":"center","color":"#333","padding-top":"20px"}},[_vm._v(" 精准高效赋能金融人")])])],_c('div',{staticClass:"js-bg",class:{ 'js-bg-m': _vm.mediaTer == 'Mobile' }},[_c('div',{ref:"about2",staticClass:"js-show",class:_vm.flag['about2'] ? 'moveToTop' : 'old-place'},[_c('h3',{staticClass:"js-title js-main"},[_vm._v("企业介绍")]),_c('div',{staticClass:"js-main"},[_vm._m(1),(_vm.mediaTer != 'Mobile')?_c('div',{staticClass:"js-right"}):_vm._e()])])]),_c('div',{ref:"fourBox",staticClass:"lc-bg",class:{ 'lc-bg-m': _vm.mediaTer == 'Mobile' }},[_c('h3',{ref:"about3",staticClass:"lc-title",class:_vm.flag['about3'] ? 'moveToTop' : 'old-place'},[_vm._v(" 发展历程 ")]),_c('div',{ref:"about4",staticClass:"lc-main",class:_vm.flag['about4'] ? 'moveToTop' : 'old-place'},[_c('div',{staticClass:"lc-main-in"},_vm._l((_vm.history),function(item,index){return _c('div',{key:'history' + index,staticClass:"lc-item"},[_c('div',{staticClass:"lc-item-left"},[_vm._v(_vm._s(item.time))]),_c('div',{staticClass:"lc-item-main"},[(index != 0)?_c('div',{staticClass:"lc-item-topline"}):_vm._e(),_c('div',{staticClass:"lc-item-dot",class:{ 'lc-item-dotact': index == 0 }}),_c('div',{staticClass:"lc-item-line"})]),_c('div',{staticClass:"lc-item-right"},[_c('div',{staticClass:"lc-item-title",domProps:{"innerHTML":_vm._s(item.title)}}),_c('div',{staticClass:"lc-item-msg",domProps:{"innerHTML":_vm._s(item.con)}})])])}),0)])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yj-main"},[_c('p',{staticClass:"yj-title"},[_vm._v("愿 景")]),_c('p',{staticStyle:{"line-height":"30px","font-size":"30px","text-align":"center","color":"#333","padding-top":"20px"}},[_vm._v("搭建领先的数字化平台 ")]),_c('p',{staticStyle:{"line-height":"30px","font-size":"30px","text-align":"center","color":"#333","padding-top":"20px"}},[_vm._v(" 精准高效赋能金融人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"js-left"},[_c('div',{staticClass:"js-con"},[_c('p',[_vm._v(" 深圳市致远速联信息技术有限公司（简称：致远速联）成立于2010年，以公司创始人贾霄鹏先生为主的核心团队自1999年开始持续为中国证监会、深圳证券交易所等核心监管机构完成信息披露、业务监管等方面信息化系统开发；团队在无间断陪伴并参与中国证券市场信息化建设24年里，全程参与了制定证券领域的信息化国家标准及行业标准；是一家植根于证券金融领域的信息处理、加工和应用的数字化技术研发型企业。公司具备一支业务经验丰富、技术能力过硬的顶级团队；在商业报告标准化及自动化方面有着十几年的研发和处理经验；多项技术和产品在业内处于领先地位，客户均为证券金融领域的顶尖机构。 ")]),_c('p',[_vm._v(" 在长时间服务资本市场过程中，致远速联逐渐构建了包含”金融数据库、监管规则库、分析模型库”的高质量高精度全量性资本市场数据库；自主研发了包含数据采集、数据分析核心引擎、计算机自主学习等数十项领先于行业的核心知识产权群；联合深圳证券通信有限公司、深圳证券信息有限公司(巨潮资讯），基于深证云构建资本市场数字化平台；针对金融业投资机构、研究机构、学术机构、监管部门、上市企业、中介服务结构和行业从业人员等不同客户的需求，为客户提供数字化系统开发、APl开放平台和BPS智能业务服务。 ")])])])
}]

export { render, staticRenderFns }