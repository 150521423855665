if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}
//软件开发环境   dev：本地开发环境 prod：生产环境
let active = process.env.NODE_ENV == 'production' ? 'prod' : 'dev' ;
const config = (function(env) {
    // 开发环境服务器地址
    const dev = {
        // api接口访问地址
        host: window.location.origin + '',
        version_path: 'cmdict',
        projectName: 'v1',
        //软件开发环境
        active: active,
        user_cookie : {}
    };
    // 生产环境服务器地址
    const build = {
        // api接口访问地址
        host: window.location.origin + '',
        version_path: 'cmdict',
        projectName: 'v1',
        //软件开发环境
        active: active,
        user_cookie : {}
    };
    if (env === 'dev') {
        return dev;
    } else if (env === 'prod') {
        return build;
    }
})(active);
window.config = eval(config);
