<template>
  <div :class="{ 'bushome-box-m': mediaTer == 'Mobile' }">
    <div class="bushome-bg" :class="{ 'bushome-bg-m': mediaTer == 'Mobile' }">
      <!-- <strong style="font-weight: 400;">联易融</strong> -->
      <h2 class="bushome-bgtext">业务介绍</h2>
    </div>
    <!-- 导航栏 -->
    <div class="busness-nav" :class="{ 'busness-m': mediaTer == 'Mobile' }">
      <div class="w" :class="{ 'w-m': mediaTer == 'Mobile' }">
        <ul
          class="navbar"
          :class="{
            'navbar-m': mediaTer == 'Mobile',
          }"
        >
        <li @click="toLink('cmdr')">
            <span :class="{ active: type == 'cmdr' }">数字化系统</span>
          </li>
          <li @click="toLink('api')">
            <span :class="{ active: type == 'api' }">API开放平台</span>
          </li>
         
          <li @click="toLink('bps')">
            <span :class="{ active: type == 'bps' }"> BPS智能业务</span>
          </li>
          <!-- <li @click="toLink('kj')">
                <span :class="{ active: type == 'kj' }">{{ nav.item4 }}</span>
              </li> -->
        </ul>
      </div>
    </div>

       <!--数字化托管平台-->
       <div
      class="bl-bg"
      :class="{ 'bl-bg-m': mediaTer == 'Mobile' }"
      ref="cmdrBox"
    >
      <h3
        class="bl-title"
        ref="busi4"
        :class="flag['busi4'] ? 'moveToTop' : 'old-place'"
      >
        {{ cmdr.name }}
      </h3>
      <div
        class="bl-msg"
        ref="busi5"
        :class="flag['busi5'] ? 'moveToTop' : 'old-place'"
      >
        <template v-if="mediaTer == 'Mobile'">
          <div v-html="cmdr.titleM"></div>
        </template>
        <template v-else>
          <div v-html="cmdr.title"></div>
        </template>
      </div>
      <div
        class="bl-img"
        ref="busi6"
        :class="flag['busi6'] ? 'moveToTop' : 'old-place'"
      >
        <div class="bimg img2" :class="{ 'img8-m': mediaTer == 'Mobile' }" @click="goTo('contact')">
          <div class="bl-mark"></div>
          <div class="bl-lab">{{ cmdr.name }}</div>
          <div class="bl-icon"></div>
        </div>
      </div>
    </div>
    <!--API开发平台-->
    <div
      class="bl-bg"
      :class="{ 'bl-bg-m': mediaTer == 'Mobile' }"
      ref="apiBox"
    >
      <h3
        class="bl-title"
        ref="busi1"
        :class="flag['busi1'] ? 'moveToTop' : 'old-place'"
      >
        {{ api.name }}
      </h3>
      <div
        class="bl-msg"
        ref="busi2"
        :class="flag['busi2'] ? 'moveToTop' : 'old-place'"
      >
        <template v-if="mediaTer == 'Mobile'">
          <div v-html="api.titleM"></div>
        </template>
        <template v-else>
          <div v-html="api.title"></div>
        </template>
      </div>
      <div
        class="bl-img"
        ref="busi3"
        :class="flag['busi3'] ? 'moveToTop' : 'old-place'"
      >
        <div class="bimg img1" :class="{ 'img8-m': mediaTer == 'Mobile' }" @click="goTo('contact')">
          <div class="bl-mark"></div>
          <div class="bl-lab">{{ api.name }}</div>
          <div class="bl-icon"></div>
        </div>
      </div>
    </div>
 
      <!--BPS智能业务平台-->
       <div
      class="bl-bg"
      :class="{ 'bl-bg-m': mediaTer == 'Mobile' }"
      ref="bpsBox"
    >
      <h3
        class="bl-title"
        ref="busi7"
        :class="flag['busi7'] ? 'moveToTop' : 'old-place'"
      >
        {{ bps.name }}
      </h3>
      <div
        class="bl-msg"
        ref="busi8"
        :class="flag['busi8'] ? 'moveToTop' : 'old-place'"  
      >
        <template v-if="mediaTer == 'Mobile'">
          <div v-html="bps.titleM"></div>
        </template>
        <template v-else>
          <div v-html="bps.title"></div>
        </template>
      </div>
      <div
        class="bl-img"
        ref="busi9"
        :class="flag['busi9'] ? 'moveToTop' : 'old-place'"
      >
        <div class="bimg img3" :class="{ 'img8-m': mediaTer == 'Mobile' }" @click="goTo('contact')">
          <div class="bl-mark"></div>
          <div class="bl-lab">{{ bps.name }}</div>
          <div class="bl-icon"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "business",
  data() {
    return {
      //  nav: this.offLang.nevBar.businessNav, //导航栏
      flag: {},
      viewHeight: 0, //页面可视区域高度
      type:  "",
      num: 1,
      intNum: "", //定时器
      mediaTer: "", //终端类型
      api: {
        title: "安全合规 渗透场景 探索资本市场数字化的共享生态",
        name: "API开放平台",
        titleM: "安全合规 渗透场景 探索资本市场数字化的共享生态",
      },
      cmdr: {
        name: "数字化系统",
        title: "深耕数据 云端加密 探索多维财富管理的创新载体",
        titleM: "深耕数据 云端加密 探索多维财富管理的创新载体",
      },
      bps: {
        name: "BPS智能业务平台",
        title: "赋能客户 面向未来 探索低耗能业务模式的云端体验",
        titleM: "赋能客户 面向未来 探索低耗能业务模式的云端体验",
      },
      //国际化
      //   core: this.offLang.business.core,
      //   sme: this.offLang.business.sme,
      //   cbt: this.offLang.business.cbt,
      //   fintech: this.offLang.business.fintech,
    };
  },
  watch: {
    $route(to, from) {
      this.type = to.query.type;
      this.toLink(this.type)
     // this.pageLink();
    },
  },
  created: function () {
    //获取访问环境
    this.mediaTer = this.getEnvironment();
  },
  mounted: function () {
    this.changeNum();
   
  
    //页面初始化
    this.pageInit("busi", 10);
    //页面滚动动画
    this.pageScrollAmi("busi", 10);
    //页面尺寸变化
    this.pageSize();
    let type=this.$route.query.type
    if(type){
        this.type=type
       //页面锚点定位
     // this.pageLink();
     this.toLink(type)
    } else{
      this.type = "cmdr";
    }
   // this.type = "api";
    
  },
  methods: {
    toLink(link) {
      let { mediaTer } = this;
      this.type = link;
      // console.log(document.documentElement.scrollTop);
      let topY = 0;
      if (link == "cmdr") {
        this.getScrollIntoView("cmdrBox", "cmdrBox", 269+284, 310+476);
      } else if (link == "bps") {
        this.getScrollIntoView("bpsBox", "bpsBox", 269+284+284+19, 310+476*2);
      
      } else {
        this.getScrollIntoView("apiBox", "apiBox", 269, 310);
      }
    },
    // 方法
    getScrollIntoView(item1, item2, top1, top2) {
      if (this.mediaTer == "Mobile") {
        this.$refs[item1].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        setTimeout(() => {
          document.documentElement.scrollTop = top1;
        }, 450);
      } else {
        this.$refs[item2].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        setTimeout(() => {
          document.documentElement.scrollTop = top2;
        }, 450);
      }
    },
    //变化数字
    changeNum: function () {
      var _this = this;
      var min = 1;
      var max = 18;
      _this.intNum = setInterval(function () {
        _this.num = Math.floor(Math.random() * (max - min)) + min;
      }, 4000);
    },
    //页面锚点定位
    pageLink: function () {
      var scroll = 0;
      var domRect = "";
      var topY = 0;
      if (this.mediaTer == "Mobile") {
      if (this.type == "api") {
        var domCore = this.$refs.apiBox;
        domRect = domCore.getBoundingClientRect();
        topY = 269;
      } else if (this.type == "cmdr") {
        var domCore = this.$refs.cmdrBox;
        domRect = domCore.getBoundingClientRect();
        topY = 269+284;
      } else if (this.type == "bps") {
        var domCore = this.$refs.bpsBox;
        domRect = domCore.getBoundingClientRect();
        topY = 269+284+284+19;
      } 
      }else{

      }
      document.documentElement.scrollTop = domRect.top;
    },
    //页面初始化
    pageInit: function (name, num) {
      var _this = this;
      _this.viewHeight = this.getViewHeight();
      //初始化dom信息
      for (let i = 1; i < num; i++) {
        _this.flag["dom" + i] = _this.$refs[name + "" + i];
        let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
        if (rectY < _this.viewHeight) {
          Vue.set(_this.flag, name + "" + i, true);
        } else {
          Vue.set(_this.flag, name + "" + i, false);
        }
        _this.$forceUpdate();
      }
    },
    //页面滚动动画
    pageScrollAmi: function (name, num) {
      var _this = this;
      window.onscroll = function () {
        for (let i = 1; i < num; i++) {
          let rectY = _this.flag["dom" + i].getBoundingClientRect().top;
          if (rectY < _this.viewHeight) {
            Vue.set(_this.flag, name + "" + i, true);
          } else {
            Vue.set(_this.flag, name + "" + i, false);
          }
          _this.$forceUpdate();
        }

        //页面滚动高度
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop > _this.$parent.scrollNum) {
          //向下滚动
          _this.$parent.hideFlag = true;
        } else {
          //向上滚动
          _this.$parent.hideFlag = false;
        }
        _this.$parent.scrollNum = scrollTop;
      };
    },
    //页面尺寸变化
    pageSize: function () {
      var _this = this;
      window.onresize = function () {
        _this.viewHeight = _this.getViewHeight();
      };
    },
    //跳转页面
    goTo: function (path) {
      // if(this.mediaTer == "Mobile") {
      //     return;
      // }
      this.$router.push({
        path: "/" + path,
        query: {},
      });
    },
    // linkTo(link){
    //   window.location.href=link
    // }
  },
  destroyed() {
    clearInterval(this.intNum);
  },
};
</script>

<style  scoped>
.bushome-box-m {
  padding-bottom: 40px;
}
/* 背景 */
.bushome-bg {
  width: 100%;
  height: 380px;
  background: url(../../static/img/business/b-bg.jpg) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bushome-bg-m {
  height: 300px; background: url(../../static/img/business/b-bg-m.png)no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}
.bushome-bgtext {
  text-indent: -888888px;
  width: 130px;
  height: 80px;
  background: url(../../static/img/business/b-bg-text.svg) no-repeat center center;
  background-size: 100%;
}
.bushome-bgtext-en {
  /*background:url(../../static/img/business/bg-text-en.svg) no-repeat center center;*/
  background-size: 100%;
  width: 380px;
  height: 100px;
}
/* 业务区块 */
.bl-bg {
  width: 1200px;
  margin: 60px auto 0;
}
.bl-title {
  font-size: 30px;
  color: #333;
  text-align: center;
  transition: all 0.5s;
}
.bl-msg {
  font-size: 20px;
  color: #333;
  text-align: center;
  margin: 6px 0 18px;
  transition: all 0.5s;
}
.bl-img {
  display: flex;
  width: 100%;
  transition: all 0.5s;
  flex-wrap: wrap;
  justify-content: center;
}
.bl-icon {
  width: 80px;
  height: 30px;
  background: url(../../static/img/common/i-right-w.svg) no-repeat center center;
  position: absolute;
  bottom: 70px;
  opacity: 0;
  transition: all 0.3s;
  left: calc(50% - 40px);
}
.bl-img .bimg {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  background-size: 100%;
  margin: 2px 4px;
  height: 320px;
  border-radius: 8px;
}
.bimgnone {
  cursor: default !important;
}
.bl-mark {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #008dff;
  opacity: 0;
  transition: all 0.3s;
  top: 0px;
  left: 0px;
  border-radius: 8px;
}
.bimg div:nth-child(2) {
  transition: all 0.3s;
  z-index: 2;
  text-align: center;
}
.bimg:hover {
  background-size: 103%;
}
.bimg:hover .bl-lab {
  padding-bottom: 30px;
}
.bimg:hover .bl-icon {
  bottom: 100px;
  opacity: 1;
}
.bimg:hover .bl-mark {
  opacity: 0.8;
}
.imgcom {
  width: calc((100% - 24px) / 3);
  flex: none !important;
  background-size: 100% 100% !important;
}
.img1 {
  background: url(../../static/img/business/b-api.jpg) no-repeat center center;
}
.img2 {
  background: url(../../static/img/business/b-szh.jpg) no-repeat center center;
}
.img3 {
  background: url(../../static/img/business/b-bps.jpg) no-repeat center center;
}
/*  .img3-1{background:url(../../static/img/business/3-1.png) no-repeat center center;flex:none !important;}
    .img4{flex:1;background:url(../../static/img/business/4.png) no-repeat center center;}
    .img5{flex:1;background:url(../../static/img/business/5.png) no-repeat center center;}
    .img6{flex:1;background:url(../../static/img/business/6.png) no-repeat center center;}
    .img7{background:url(../../static/img/business/new7.png) no-repeat center center;}
    .img8{background:url(../../static/img/business/new8.png) no-repeat center center;}
    .img9{background:url(../../static/img/business/new9.png) no-repeat center center;}
    .img10{background:url(../../static/img/business/new10.png) no-repeat center center;}
    .img11{background:url(../../static/img/business/new11.png) no-repeat center center;}
    .img8-m{flex:1;background:url(../../static/img/business/8-m.png) no-repeat center center;} */
.bl-bg-m {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 40px;
}
.bl-bg-m .bl-title {
  font-size: 24px;
}
.bl-bg-m .bl-msg {
  font-size: 14px;
}
.bl-bg-m .bimg {
  width: 100%;
  flex: none;
  height: 200px;
  margin: 0 0 10px 0;
  font-size: 20px;
}
.bl-bg-m .bimg:hover {
  background-size: 100%;
}
.bl-bg-m .bimg:hover .bl-lab {
  padding-bottom: 00px;
}
.bl-bg-m .bimg:hover .bl-icon {
  bottom: 50px;
  opacity: 0;
}
.bl-bg-m .bimg:hover .bl-mark {
  opacity: 0;
}
h3 {
  font-weight: 400;
}
/* 导航栏 */
/* 版心 */
.w {
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}
.w-m {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}

.busness-nav {
  position: sticky;
  z-index: 3;
  top: 70px;
  box-sizing: border-box;
  width: 100%;
  background-color: #f7f7f7;
}
.busness-nav .navbar {
  display: flex;
  align-items: center;
}
.busness-nav .navbar li {
  margin-right: 48px;
  text-align: center;
  font-size: 16px;
  color: #666666;
  font-family: PingFangSC, PingFangSC-Regular;
  cursor: pointer;
}
.busness-nav .navbar li span {
  display: inline-block;
  line-height: 52px;
}
.busness-nav .navbar li span.active {
  color: #008dff;
  border-bottom: 2px solid #008dff;
}
.busness-m {
  top: 59px;
}

.busness-nav .w-m {
  padding-right: 100px;
  overflow: auto;
}
.busness-nav .navbar-m {
  justify-content: space-around;
  width: 350px;
}
.busness-nav .navbar-m li {
  margin-right: 0px;
}
.busness-nav .w-m {
  box-sizing: border-box;
}
.busness-nav .w-m .navbar-m-e {
  width: 500px;
  overflow: auto;
}
.busness-nav .w-m .navbar-m-e li {
  font-size: 12px;
}
</style>