
import {Ajax} from "@/libs/request"
import apiurl from "@/config/apiurl"
const s3url=process.env.NODE_ENV === 'development'?apiurl.s3url.dev:apiurl.s3url.pro;
export default {
    // 检测文件是否存在
    FileExit(data,callback,error){
        Ajax({
            url:s3url+"sys/key/info",
            apitype:"s3",
            data:data,
            success:callback,
            error:error
        })
    },
    // office转pdf

    Office2Pdf(data,callback,error){
        Ajax({
            url:s3url+"biz/key/office2pdf",
            apitype:"s3",
            data:data,
            success:callback,
            error:error
        })
    },
    // 文件下载链接
    FileDown(data){
        return encodeURIComponent(s3url+"sys/key/download?"+data)
    },
    // 下载json文件
    FileDownJson(url,callback,error){
        Ajax({
            url:url,
            method:"GET",
            dataType: "json",
            success:callback,
            error:error
        })
    },
    //文件上传
    s3upload(data,callback,error){
      Ajax({
          url:s3url+"sys/key/upload",
          apitype:"s3",
          data:data,
          upload:true,
          success:callback,
          error:error
      })
    },
    // 文件分享
    s3share(data,callback,error){
        Ajax({
            url:s3url+"biz/key/share",
            apitype:"s3",
            data:data,
            success:callback,
            error:error
        })
    },
    // 下载分享链接
    s3sharedown(data){
        return encodeURIComponent(s3url+"biz/key/shareDownload?path="+data)
    }
}