
import axios from "./axios"
import { Loading,MessageBox } from 'element-ui';
function Ajax(options) {
    let params = new URLSearchParams();
    let method = options.method ? options.method : "POST"
    let loadingInstance = !options.nofullscreen? Loading.service({
        text:"拼命加载中",
        spinner:"el-icon-loading",
        background:"rgba(0, 0, 0, 0.8)"

    }):Object;
    axios({
       // baseURL:options.baseURL,
        url:options.url,
        method:method,
        data:options.data,
        headers: options.upload ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {'Content-Type': 'application/json'},
       // emulateJSON: true

    }).then((res)=>{
        if(!options.nofullscreen){
        loadingInstance.close();
        }
        options.success && typeof options.success === 'function' ? options.success(res.data) : '';
        
    },function(error) {
        if(!options.nofullscreen){
        loadingInstance.close();
        }
       
        MessageBox.alert(error.message,"错误提示",{type:"error"})
        options.error && typeof options.error === 'function' ? options.error(error) : '';
      
        console.log("error:"+error)
    })

}


export { Ajax };