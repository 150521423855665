<style scoped>
    .foot-bg{width: 100%;background-color: #fff;margin-top: 100px;}
    .foot-bgw{background-color: #fff;margin-top: 0px;}
    .foot-main{width: 1200px;margin: auto;}
    .foot-top{display: flex;justify-content: space-between;align-items: center;padding: 60px 0}
    .foot-top .link-box{display: flex;justify-content: space-around;width: 800px}
    .foot-top .link-box-en{justify-content: space-between;width: 820px}
    .link-box-en .link-lab{height: 50px;}
    .link-box-en .link-item{margin: 0 20px;}
    .link-box-en .link-item:first-child{margin-left: 0;}
    .link-box-en .link-item:last-child{margin-right: 0;}
    .link-item{flex: 1}
   
    .link-item .link-lab{font-size: 20px;color: #666;margin-bottom: 26px;font-weight: 400}
    .link-item .link-con{display: block;font-size: 16px;color: #666;margin-bottom: 12px;cursor: pointer;}
    .link-item p {color: #ddd;}
    .link-item .link-con:hover{color: #008DFF}
    .foot-top .logo-box{display: flex;}
    .foot-top .logo-item{display: flex;flex-direction: column;align-items: center;font-size: 12px;color: #999;margin-left: 20px;}
    .foot-top .logo-item div:nth-child(1){width: 130px;height: 130px;border-radius: 8px;border: 1px solid #E2E2E2;margin-bottom: 8px;}
    .logo-weixin{ background: url(../../static/img/common/public.png)no-repeat center center;}
    .logo-linkedin{ background: url(../../static/img/common/eaeapublic.png)no-repeat center center;}
    .foot-logo{width: 200px;height: 100px; /*background:url(../../static/img/common/i-logo.svg) no-repeat left center;*/}
    .foot-weixin{display: flex;align-items: center;font-size: 20px;color: #666;padding-left: 10px;}
    .weixin-icon{width: 50px;height: 50px;/*background:url(../../static/img/common/i-weixin.svg) no-repeat center center;*/position: relative;cursor: pointer;}
    .weixin-out{width: 150px;height: 170px;/*background:url(../../static/img/common/out.svg) no-repeat center center;*/position:absolute;left: -50px;top: -175px;transition: all 0.3s;opacity: 0;display: flex;justify-content: center;align-items: center;background-size: 100%;}
    .weixin-img{/*background: url(../../static/img/common/linklogis.png) no-repeat center center;*/width: 145px;height: 140px;background-size: 100%;}
    .weixin-icon:hover{/*background:url(../../static/img/common/i-weixin-b.svg) no-repeat center center;*/}
    .weixin-icon:hover .weixin-out{opacity: 1;top: -155px;}
    .foot-bottom{border-top: 1px solid #ECECEC;width: 100%;padding-top:20px;display: flex;height: 50px;justify-content:space-between;color:#666;font-size: 14px;position: relative; }
    .foot-bottom .icp-box{display: flex;}
    .foot-tips {
      position: absolute;
      left: 0;
      top: 45px;
      line-height: 20px;
      padding-bottom: 10px;
    }
    .icp-box div{margin-left: 20px;display: flex;}
    .icp-box div a{color: #666;}
    .icp-img{width: 20px;height: 20px;}
</style>

<template>
    <footer class="foot-bg" >
        <div class="foot-main">
            <div class="foot-top">
                <!-- 链接专区 -->
                <div class="link-box" >
                    <div class="link-item">
                      <div class="link-lab">关于我们</div>
                      
                    
                      <router-link class="link-con" :to="{ name: '关于我们', query: { type: 'one' }}">愿景使命</router-link>
                      <router-link class="link-con" :to="{ name: '关于我们', query: { type: 'two' }}">企业介绍</router-link>
                   
                      <router-link class="link-con" :to="{ name: '关于我们', query: { type: 'four' }}">发展历程</router-link> 
                    </div>
                    <div class="link-item">
                        <div class="link-lab">业务介绍</div>
                       
                      
                       
                        <router-link class="link-con" :to="{ name: '业务介绍', query: { type: 'api' }}">API开放平台</router-link>
                        <router-link class="link-con" :to="{ name: '业务介绍', query: { type: 'cmdr' }}">数字化托管工作台</router-link>
                        <router-link class="link-con" :to="{ name: '业务介绍', query: { type: 'bps' }}">BPS智能业务平台</router-link>
                      
                    </div>
                    <!-- <div class="link-item">
                        <div class="link-lab">公司地址</div>
                        <p> 深圳市罗湖区红岭中路2068号中深国际大厦24楼</p>
                       
                    </div>
                    <div class="link-item">
                        <div class="link-lab">{{foot.join.title}}</div>
                        <div class="link-con" @click="goToOut('https://hr.linklogis.com/#/socialJoin')">{{foot.join.item1}}</div>
                        <div class="link-con" @click="goToOut('https://hr.linklogis.com/#/schoolJoin')">{{foot.join.item2}}</div>
                    </div>-->
                    <div class="link-item">
                        <div class="link-lab">联系我们</div>
                        <!-- <div class="link-con" @click="goTo('contact','cont1')">联系方式</div> 
                        <div class="link-con" @click="goTo('contact','twoBox')">联系地址</div>  -->
                         <router-link class="link-con" :to="{ name: '联系我们', query: { type: 'cont1' }}">联系方式</router-link>
                        <router-link class="link-con" :to="{ name: '联系我们', query: { type: 'twoBox' }}">联系地址</router-link>
                       
                    </div>
                </div>
                <!-- logo专区 -->
                <!-- <div class="logo-box">
                    <div class="foot-logo"></div>
                    <div class="foot-weixin">关注我们<div class="weixin-icon"><div class="weixin-out"><div class="weixin-img"></div></div></div></div>
                </div> -->
                <div class="logo-box">
                    <div class="logo-item">
                        <div class="logo-weixin"></div>
                        <div>关注致远速联</div>
                    </div>
                    <div class="logo-item">
                        <div class="logo-linkedin"></div>
                        <div>关注易问易答</div>
                    </div>
                </div>
            </div>
            <div class="foot-bottom">
                <div class="copy-box" >深圳市致远速联信息技术有限公司 版权所有</div>
                <!-- <div class="foot-tips" v-html="foot.other.item6"></div> -->
                <div class="icp-box">
                    <div><a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备13055653号-2</a></div>
                    <div>
                       
                        <!-- <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502001427" target="_blank">{{foot.other.item5}}</a> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        components: {},
        props:{
           theme : "",//【非必传】，主题颜色
        },
        data (){
            return {
                curTheme: this.theme,
                //国际化
               // foot: this.offLang.foot,
            }
        },
        watch: {
            theme: function(newVal,oldVal) {
              console.log(this.$route);
                this.curTheme = newVal;
            }
        },
        methods() {
          
        },
        mounted:function(){
          // 临时处理
          if (this.$route.fullPath=='/' || this.$route.fullPath=='/welcome/') {
            this.curTheme = 'white'
          }
        },
        methods:{
            //页面跳转
            goTo: function(path,type) {
                var param = (type ? {type : type} : {});
                // console.log(param);
                this.$router.push({
                    path: '/'+path,
                    params: param
                }) 
            }
        }
    }
</script>


