import moment from "moment"

/**
 * @fileOverview 监管前端脚手架通用功能封装
 * @author 郭猛
 * @module $jgGlobalFuc
 */
export default {
    /**
     * 生成UUID
     * @return {string}
     */
    getUUID_resource() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "";
        var uuid = s.join("");
        return uuid;
    },

    /**
     * 字符串左侧补零
     * @param str
     * @return {string}
     *
     * @example padLeftZero( 'abc', 3 ); //'abc'
     * padLeftZero( 'abc', 3 ); //'abc'
     * padLeftZero( 'abc', 4 ); //'0abc'
     */
    padLeftZero(str, length = 2) {
        return ( new Array( length ).fill( '0' ).join('') + str).substr(str.length);
    },
    /**
     * 标准时间格式化为字符串
     * @param {date} date Date型数据
     * @param {string} fmt 格式化类型
     */
    formatDate(date, fmt) {
        let _method = fmt.match( /\{.+\}?/g );
        let _moment = moment( date )
        if( _method ){
            let _methodConf = _method[0].slice( 1, -1 ).split( ':' );
            _moment.add( _methodConf[1], _methodConf[0] );
        }
        return _moment.format( fmt.replace( /\{.+\}?/g, '' ) );
    },
    /**
     * 获取当前时间
     * @param {boolean} needSecond 是否需要返回时分秒，默认不需要
     */
    getToday(needSecond) {
        return this.formatDate( new Date(), `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 获取明天的日期
     * @param {boolean} needSecond 是否需要返回时分秒，默认不需要
     */
    getNextDate(needSecond) {
        return moment( new Date() ).add( 1, 'd' ).format( `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 获取当前系统时间前多少天
     * @param {string} datt 日期 "2000-01-01"
     * @param {number} value 前几天
     */
    getBeforeDate(datt, value, needSecond = false ) {
        return moment( new Date( datt ) ).add( value * -1, 'd' ).format( `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 根据出生日期计算年龄
     * @param {string} birthday "2000-01-01"
     */
    getAgeByBirth(birthday) {
        return moment( new Date( birthday ) ).diff( moment( new Date() ), 'y' ) * -1;
    },
    /**
     * 生成错误返回值对象
     * @param code
     * @param msg
     */
    errResult( code, msg ){
        return { status:false, code:code, context:msg };
    }
}